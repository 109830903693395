import React, {useState} from 'react';

import ActivityContent from './ActivityContent';
import ActivityFooter from './ActivityFooter';
import ActivityHeader from './ActivityHeader';
import CommentSection from "./Comment/CommentSection";
import SpecialActivityHeader from "./SpecialActivityHeader";
import classNames from "classnames";
import {deleteActivity} from "../../../../redux/actions/activityActions";
import {connect} from "react-redux";
import Modal from "../../../_ui/Modal/Modal";
import {addFlashMessage} from "../../../../redux/actions/flashMessageActions";

const Activity = ({ uuid, author, tags, comments, contents, special, occurredAt, dispatchDeleteActivity, dispatchAddFlashMessage }) => {
    const [showComments, setShowComments ] = useState(false);
    const [commentsCount, setCommentsCount] = useState(comments.total);
    const [deleted, setDeleted] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    const increaseCommentsCount = () => {
        setCommentsCount(commentsCount + 1);
    }

    const handleDeleteClicked = () => {
        setShowDeleteModal(true);
    }

    const handleDeleteConfirmed = () => {
        setIsDeleting(true);
        dispatchDeleteActivity(
            uuid,
            () => {
                dispatchAddFlashMessage('Activity was deleted.', 'success');
                setDeleted(true);
                setShowDeleteModal(false);
                },
            () => {
                dispatchAddFlashMessage('We couldn\'t delete your activity. Please try again later.', 'error');
                setIsDeleting(false)
            }
        );
    }

    return (
        <>
        <div className={classNames('activity', {"special": special, "deleted": deleted}, special)}>
            { special
                ? <SpecialActivityHeader
                    handleDeleteClicked={ handleDeleteClicked }
                    special={ special }
                    uuid={ uuid }
                    author={ author }
                    occurredAt={ occurredAt }
                />
                : <ActivityHeader
                    handleDeleteClicked={ handleDeleteClicked }
                    uuid={ uuid }
                    author={ author }
                    occurredAt={ occurredAt }
                />
            }
            <ActivityContent { ...contents } />
            <ActivityFooter
                uuid={ uuid }
                showComments={() => setShowComments(true) }
                commentsCount={ commentsCount }
                comments={ comments }
                tags={ tags }
            />
            { showComments && <CommentSection
                commentsCount={ commentsCount }
                increaseCommentsCount={ increaseCommentsCount }
                setCommentsCount={ setCommentsCount }
                activityUuid={ uuid }
                comments={ comments }
            /> }
        </div>
            <Modal
                initialIsOpened={showDeleteModal}
                onCloseHandler={() => setShowDeleteModal(false)}
                title='Delete activity?'
                className='danger'
            >
                <div className='body' style={{textAlign:"center"}}>
                    <button disabled={isDeleting} onClick={ () => setShowDeleteModal(false) } className={'small'}>cancel</button>
                    <button disabled={isDeleting} onClick={ handleDeleteConfirmed } className='small danger'>confirm</button>
                </div>

            </Modal>
            </>
    )
}

const mapDispatchToProps = (dispatch) => ({
    dispatchAddFlashMessage: (message, type) => dispatch(addFlashMessage(message, type)),
    dispatchDeleteActivity: (activityUuid, onSuccess, onError) =>
        dispatch(deleteActivity(
                activityUuid,
                onSuccess,
                onError
            )
        ),
});

export default connect(null, mapDispatchToProps)(Activity);