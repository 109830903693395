import React from 'react';
import Followers from "./SocialTools/Followers";
import FollowersAwaiting from "./SocialTools/FollowersAwaiting";
import FollowersSearch from "./SocialTools/FollowersSearch";
import Following from "./SocialTools/Following";

export const SocialToolsSection = () => {
    return (
        <>
            <section className='stick-top stick'>
                <Followers />
            </section>
            <section className='stick-top stick'>
                <FollowersAwaiting />
            </section>
            <section>
                <FollowersSearch />
            </section>
            <section><h6>Followed teams</h6></section>
            <section>
                <Following />
            </section>
            <section><h6>Followed places</h6></section>
            <section><h6>Favorite topics</h6></section>
        </>
    );
}

export default SocialToolsSection;