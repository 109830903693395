import React, {useState} from 'react';
import classNames from "classnames";
import {useSwipeable} from "react-swipeable";

const Multimedia = ({ items }) => {
    const [current, setCurrent] = useState(items[0]);
    const handlers = useSwipeable({
        onSwipedLeft: (eventData) => {
            const currentIndex = items.findIndex((item) => item.uuid === current.uuid);
            const maxIndex = items.length - 1;

            if (currentIndex < maxIndex) {
                setCurrent(items[currentIndex + 1]);
            }
        },
        onSwipedRight: (eventData) => {
            const currentIndex = items.findIndex((item) => item.uuid === current.uuid);

            if (currentIndex > 0) {
                setCurrent(items[currentIndex - 1]);
            }
        },
        ...{
            delta: 10,                            // min distance(px) before a swipe starts. *See Notes*
            preventDefaultTouchmoveEvent: false,  // call e.preventDefault *See Details*
            trackTouch: true,                     // track touch input
            trackMouse: false,                    // track mouse input
            rotationAngle: 0,                     // set a rotation angle
        },
    });

    return (
        <div {...handlers} className='multimedia'>
            <img src={ current.url + '?token=' + JSON.parse(localStorage.getItem('authorization')).token} alt={ current.uuid } onError = {e => e.target.style.display = 'none'} />

            { items.length > 1 && <div className='thumbnails'>
                { items.map((item, key) =>
                    <span
                        className={ classNames({ active: item.uuid === current.uuid }) }
                        onClick={ (e) => setCurrent(item) }
                        key={ item.uuid }
                    >
                        { key + 1 }
                    </span>)
                }
            </div> }
        </div>
    );
}

export default Multimedia;