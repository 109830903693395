import React, { useEffect, useState } from 'react';
import { connect } from "react-redux";
import {fetchChallenges} from "../../../../../redux/actions/challengeActions";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import { prepareShortTitle } from "../../../../../utils/challengeUtils";
import moment from "moment";

export const Challenges = ({user, participatingChallenge, dispatchParticipatingFetchChallenges }) => {
    const [, setParticipating] = useState([]);
    const { t, i18n } = useTranslation(['challenges', 'disciplines']);

    useEffect(() => {
        let isSubscribed = true;

        dispatchParticipatingFetchChallenges(
            (response) => isSubscribed ? setParticipating(response) : null
        );

        return () => isSubscribed = false;
    }, [dispatchParticipatingFetchChallenges]);

    return <section className='special'>
        {/*<h6>{ t('challenges:section.title') }</h6>*/}
        <h6>{ t('challenges:section.upcoming.title') }</h6>
        <div>
            <button className={'small'}>{ t('challenges:section.createButtonLabel') }</button> <button className={'small'}>{ t('challenges:section.searchAndJoinLabel') }</button>
        </div>
        { participatingChallenge.length > 0 && <>
        <div className={'incoming-challenges'}>
        { participatingChallenge.map((challenge) => <div className={ 'challenge' } key={ challenge.uuid}>
            <Link className={'title'} to={ '/challenges/' + challenge.uuid }>
                { prepareShortTitle(challenge, user, t, i18n) }
            </Link>
            <div style={{ display: 'block', fontSize: '14px', color: '#fff' }}>{ moment(challenge.date, null, i18n.language).fromNow() }</div>
        </div>) }
        </div>
        </> }
    </section>
}

const mapStateToProps = (state) => ({
    user: state.user,
    participatingChallenge: state.participatingChallenge
});
const mapDispatchToProps = (dispatch) => ({
    "dispatchParticipatingFetchChallenges": (onSuccess) => dispatch(fetchChallenges({ search: { participating: true, upcoming: true}}, onSuccess, (errorResponse) => console.log(errorResponse)))
});

export default connect(mapStateToProps, mapDispatchToProps)(Challenges);