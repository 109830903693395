import React from 'react';
import classNames from "classnames/bind";

import './Ico.scss'

const Ico = (props) => {
    const icoStyle = {
        ico: true
    };

    return(
        <div className={ classNames(icoStyle, props.className) }>
            { props.badge > 0 && <span className="badge">{ props.badge}</span> }
        </div>
    )
}

export default Ico;