import React, { useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { fetchChallenge } from "../../../../../../redux/actions/challengeActions";
import Avatar from "../../../../../_ui/Avatar/Avatar";
import moment from "moment";
import {useTranslation} from "react-i18next";
import {prepareShortTitle} from "../../../../../../utils/challengeUtils";

const InContentChallenge = ({challengeUuid, user, key, dispatchFetchChallenge}) => {
    const { t, i18n } = useTranslation(['challenges', 'disciplines']);
    const [loading, setLoading] = useState(true);
    const [challenge, setChallenge] = useState(null);

    useState(
        () => {
            const fetchSuccess = res => {
                setLoading(false);
                setChallenge(res);
            };

            dispatchFetchChallenge(challengeUuid, (res) => fetchSuccess(res), (errorResponse) => console.log(errorResponse));
        },
        []
    );

    return <Link className='in-content-challenge' key={ key } to={ '/challenges/' + challengeUuid + '/' }>
        { loading
            ? 'challenge data is loading'
            : challenge && <>
                <Avatar className='small' image={ challenge.organizer.avatar} />
            { prepareShortTitle(challenge, user, t, i18n) } {  moment(challenge.date, null, i18n.language).fromNow() }
            </>
        }
    </Link>
}

const mapStateToProps = (state) => ({
    user: state.user
});
const mapDispatchToProps = (dispatch) => ({
    "dispatchFetchChallenge": (challengeUuid, onSuccess, onError) => dispatch(fetchChallenge(challengeUuid, onSuccess, onError)),
});

export default connect(mapStateToProps, mapDispatchToProps)(InContentChallenge);