import React, {useEffect, useState} from "react";
import {Column, Container, Row} from "../../../../components/_ui/Grid";
import {useTranslation} from "react-i18next";
import Brand from "../../../../components/_ui/Branding/Brand";
import ReactMarkdown from "react-markdown";

const AboutSection = () => {
    const { t } = useTranslation(["landingPage"]);
    const initialBackgroundPosition = -750;
    const [backgroundPosition, setBackgroundPosition] = useState(initialBackgroundPosition);

    useEffect(() => {
        let isSubscribed = true;

        const animateBackground = () => {
            const d = document.documentElement;
            const offset = d.scrollTop + window.innerHeight;
            const height = d.offsetHeight;

            isSubscribed && setBackgroundPosition(initialBackgroundPosition + (-1 * initialBackgroundPosition * offset / height));
        };

        window.addEventListener('scroll', animateBackground);

        return () => {
            document.removeEventListener("scroll", animateBackground);
            isSubscribed = false;
        };
    }, [initialBackgroundPosition]);

    return (
        <section className="landing-page-about-section" style={{backgroundPosition: backgroundPosition + "px bottom"}}>
            <div className="arrow top-left" />
            <div className="arrow bottom-right" />
            <Container>
                <Row className="center title">
                    <h2>{ t('landingPage:about.title') }</h2>
                </Row>
                <div className='about-container'>
                    <Row>
                        <Column>
                            <Brand className="big white" />
                            <div className="about-text">
                                <ReactMarkdown>{ t('landingPage:about.text') }</ReactMarkdown>
                            </div>
                            <div className='mobile-apps'>
                                <h6>{ t('landingPage:about.mobileApps.title') }</h6>
                                <img src="/img/appstore.png" alt={ t('landingPage:about.mobileApps.appstore') } />
                                <img src="/img/googleplay.png" alt={ t('landingPage:about.mobileApps.googleplay') } />
                            </div>
                        </Column>
                    </Row>
                </div>
            </Container>
        </section>
    );
}

export default AboutSection;