import React from "react";

import './LandingPage.scss';

import FormSection from "./components/Section/FormSection";
import AboutSection from "./components/Section/AboutSection";
import {Helmet} from "react-helmet";

const LandingPage = () => {

    return (
        <React.Fragment>
            <Helmet>
                <link rel="canonical" href="https://sportzzy/hi/" />
            </Helmet>
            <FormSection />
            <AboutSection />
        </React.Fragment>
    );
}

export default LandingPage;