import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {connect} from "react-redux";
import {fetchProfileFollowers} from "../../../../../redux/actions/profileFollowerAction";
import {Link} from "react-router-dom";
import Avatar from "../../../../../components/_ui/Avatar/Avatar";

export const Followers = ({ user, dispatchFetchProfileFollowers }) => {
    const { t } = useTranslation(["followers"]);
    const [followersLoading, setFollowersLoading] = useState(true);
    const [followers, setFollowers] = useState([]);

    useEffect(() => {
        let isSubscribed = true;
        dispatchFetchProfileFollowers(
            user.profile.uuid,
            (response) => isSubscribed ? handleFetchProfileFollowersSuccess(response) : null
        );

        return () => { isSubscribed = false };
    }, [user.profile.uuid, dispatchFetchProfileFollowers]);

    const handleFetchProfileFollowersSuccess = response => {
        setFollowers(response);
        setFollowersLoading(false);
    }

    return (
        <div>
            <h6>{ t('followers:section.title') }</h6>
            <div>
                { followersLoading
                    ? [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19].map((index) => <div key={index} className={'placeholder'} style={{ display: "inline-block", backgroundColor: "#e1e1e1", width: '25px', height: '25px', borderRadius: '50%' }} />)
                    : followers.map((follower) =>
                        <Link to={ '/' + follower.path + '/'} key={follower.uuid} title={follower.name} >
                            <Avatar image={follower.avatar} className='small' />
                        </Link>
                    )
                }
                { !followersLoading
                    && followers.length === 0
                    && <div className='text-muted' style={{ fontSize: '80%' }}>{ t('followers:section.empty') }</div> }
            </div>
        </div>
    );
}

const mapStateToProps = (state) => ({ user: state.user});
const mapDispatchToProps = (dispatch) => ({
    dispatchFetchProfileFollowers: (profileUuid, onSuccess) => dispatch(fetchProfileFollowers(profileUuid, onSuccess, (errorResponse) => console.log(errorResponse))),
});

export default connect(mapStateToProps, mapDispatchToProps)(Followers);