import React from "react";
import { Link } from "react-router-dom";
import classNames from "classnames";

import './Brand.scss';

const Brand = ({ href, main, size, className, linkClassName, style, linkStyle }) => {
    const brandClassNames = classNames('brand', size, className);
    const brand = main
        ? <h1 className={ brandClassNames } style={ style }>sportzzy</h1>
        : <div className={ brandClassNames } style={ style }>sportzzy</div>;

    return (typeof href === 'string'
        ? <Link to={ href } className={ classNames('brand-link', linkClassName) } style={ linkStyle }>{ brand }</Link>
        : brand
    );
}

export default Brand;