import * as constants from './../constants';

import shortid from 'shortid';

export const addFlashMessage = (message, type) => ({
    type: constants.ADD_FLASH_MESSAGE,
    payload: {
        id: shortid.generate(),
        message: message,
        type: type
    }
});

export const deleteFlashMessage = (id) => ({
    type: constants.DELETE_FLASH_MESSAGE,
    payload: {
        id: id
    }
});