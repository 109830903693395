import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import ReactMarkdown from "react-markdown";
import {signUp} from '../../../../redux/actions/authActions';
import {NAME, CITY, EMAIL, PASSWORD} from "../../../../utils/regex";

import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import classNames from "classnames";

const SignUpForm = ({dispatchSignUpAction}) => {
    const {t} = useTranslation(["translation", "auth"]);

    const [{email, password, firstName, lastName, city}, setFormField] = useState({
        email: '', password: '', firstName: '', lastName: '', city: ''
    });
    const [error, setError] = useState({});
    const [pendingRequest, setPendingRequest] = useState(false);
    const [formSent, setFormSent] = useState(false);

    const handleFormFieldChange = ({target: {name, value}}) => {
        setError({...error, [name]: undefined});
        setFormField(prevState => ({...prevState, [name]: value}));
    }

    const setFormFieldError = (field, message) => setError({...error, [field]: message});

    const validateEmail = () => {
        if (email === '') {
            setFormFieldError('email', t('landingPage:form.email.error.empty'));

            return false;
        } else if (!EMAIL.test(String(email).toLowerCase())) {
            setFormFieldError('email', t('landingPage:form.email.error.invalid'));

            return false;
        }

        return true;
    }

    const validatePassword = () => {
        if (password === '') {
            setFormFieldError('password', t('landingPage:form.password.error.empty'));
        } else if (!PASSWORD.test(String(password))) {
            setFormFieldError('password', t('landingPage:form.password.error.invalid'));
        }
    }

    const validateFirstName = () => {
        if (firstName.trim() === '') {
            setFormFieldError('firstName', t('landingPage:form.firstName.error.empty'));
        } else if (!NAME.test(String(firstName).trim())) {
            setFormFieldError('firstName', t('landingPage:form.firstName.error.invalid'));
        }
    }

    const validateLastName = () => {
        if (lastName.trim() === '') {
            setFormFieldError('lastName', t('landingPage:form.lastName.error.empty'));
        } else if (!NAME.test(String(lastName.trim()))) {
            setFormFieldError('lastName', t('landingPage:form.lastName.error.invalid'));
        }
    }

    const validateCity = () => {
        if (city !== '' && !CITY.test(String(city).trim())) {
            setFormFieldError('city', t('landingPage:form.city.error.invalid'));

            return false;
        }

        return true;
    }

    const handleSubmit = (event) => {
        event.preventDefault();

        setPendingRequest(true);
        dispatchSignUpAction(email.trim(), password.trim(), firstName.trim(), lastName.trim(), city.trim(),
            (response) => handleSuccessResponse(response),
            (errorResponse) => handleErrorResponse(errorResponse),
        )
    };

    const handleErrorResponse = errorResponse => {
        setError(errorResponse.errors);
        setPendingRequest(false);
    }

    const handleSuccessResponse = response => {
        setError({});
        setFormField({
            email: '', password: '', firstName: '', lastName: '', city: ''
        });
        setPendingRequest(false);
        setFormSent(true);
    }

    return (
        formSent ?
            <React.Fragment>
                <ReactMarkdown>{t('landingPage:signUp.successMessage')}</ReactMarkdown>
                <div style={{margin: "15px"}}>
                    <Link className='btn primary' to='/hi/'>{t('translation:signIn')}</Link>
                </div>
            </React.Fragment>
            :
            <React.Fragment>
                <form onSubmit={handleSubmit} method='post' autoComplete='on'>
                    <div className={classNames({'form-group': true, 'has-error': error.email !== undefined})}>
                        <input
                            readOnly={pendingRequest}
                            required
                            id='signUpEmail'
                            type='email'
                            name='email'
                            autoComplete='username'
                            placeholder={t('landingPage:form.email.placeholder')}
                            value={email}
                            maxLength='64'
                            className={classNames({'has-error': error.email !== undefined})}
                            onChange={handleFormFieldChange}
                            onBlur={validateEmail}
                        />
                        {error.email !== undefined && <small className='hint'>{error.email}</small>}
                    </div>
                    <div className={classNames({'form-group': true, 'has-error': error.password !== undefined})}>
                        <input
                            readOnly={pendingRequest}
                            required
                            id='signUpPassword'
                            type={'password'}
                            name='password'
                            autoComplete='new-password'
                            placeholder={t('landingPage:form.password.placeholder')}
                            value={password}
                            maxLength='64'
                            className={classNames({'has-error': error.password !== undefined})}
                            onChange={handleFormFieldChange}
                            onBlur={validatePassword}
                        />
                        {error.password !== undefined && <small className='hint'>{error.password}</small>}
                    </div>
                    <div className={classNames({'form-group': true, 'has-error': error.firstName !== undefined})}>
                        <input
                            readOnly={pendingRequest}
                            required
                            id='signUpFirstName'
                            type='text'
                            name='firstName'
                            autoComplete='given-name'
                            placeholder={t('landingPage:form.firstName.placeholder')}
                            value={firstName}
                            maxLength='64'
                            className={classNames({'has-error': error.firstName !== undefined})}
                            onChange={handleFormFieldChange}
                            onBlur={validateFirstName}
                        />
                        {error.firstName !== undefined && <small className='hint'>{error.firstName}</small>}
                    </div>
                    <div className={classNames({'form-group': true, 'has-error': error.lastName !== undefined})}>
                        <input
                            readOnly={pendingRequest}
                            required
                            id='signUpLastName'
                            type='text'
                            name='lastName'
                            autoComplete='family-name'
                            placeholder={t('landingPage:form.lastName.placeholder')}
                            value={lastName}
                            maxLength='64'
                            className={classNames({'has-error': error.lastName !== undefined})}
                            onChange={handleFormFieldChange}
                            onBlur={validateLastName}
                        />
                        {error.lastName !== undefined && <small className='hint'>{error.lastName}</small>}
                    </div>
                    <div className={classNames({'form-group': true, 'has-error': error.city !== undefined})}>
                        <input
                            readOnly={pendingRequest}
                            id='signUpCity'
                            type='text'
                            name='city'
                            placeholder={t('landingPage:form.city.placeholder')}
                            value={city}
                            maxLength='64'
                            className={classNames({'has-error': error.city !== undefined})}
                            onChange={handleFormFieldChange}
                            onBlur={validateCity}
                        />
                        {error.city !== undefined && <span className='hint'>{error.city}</span>}
                    </div>
                    <div className="form-group">
                        <small className="hint">
                            <ReactMarkdown components={{
                                a: (props) => {
                                    return (<Link to={props.href}>{props.children}</Link>);
                                },
                                p: (props) => {
                                    return props.children;
                                }
                            }}>
                                {t('landingPage:form.agreementNote')}
                            </ReactMarkdown>
                        </small>
                    </div>
                    <div className="form-group">
                        <input
                            value={t('translation:signUp')}
                            type="submit"
                            className="btn primary"
                            style={{display: 'inline-block', width: 'inherit'}}
                            disabled={pendingRequest}
                        />
                    </div>
                </form>
            </React.Fragment>
    );
};

const mapDispatchToProps = dispatch => ({
    "dispatchSignUpAction": (email, password, firstName, lastName, city, onSuccess, onError) =>
        dispatch(signUp({email, password, firstName, lastName, city}, onSuccess, onError))
});

SignUpForm.propTypes = {
    dispatchSignUpAction: PropTypes.func.isRequired
};

export default connect(null, mapDispatchToProps)(SignUpForm);