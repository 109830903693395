import React from 'react';
import Challenges from "./AvtivityTools/Challenges";

export const ActivityToolsSection = () => {

    return (
        <>
            <section className='stick-top'>
                <h6>Badges & achievements</h6>
                <div className='text-muted' style={{ fontSize: '80%' }}>Stay active to achieve badges and trophies.</div>
            </section>

            <Challenges />

            <section>
                <h6>unconfirmed invitations</h6>
            </section>
        </>
    );
}

export default ActivityToolsSection;