export const firstNameInstrumental = (name, language) => {
    let firstName = name.split(' ')[0];

    if (language !== 'pl') return firstName;

    const firstNameToLower = firstName.toLowerCase();

    if (firstNameToLower === 'beatrycze') return firstName;
    if (firstNameToLower.charAt(firstName.length - 1) === 'ś') return firstName.slice(0, -1) + 'siem'; // Piotruś -> Piotrusiem
    if (firstNameToLower.substring(firstName.length - 4) === 'ciek') return firstName.slice(0, -4) + 'ćkiem'; // Maciek -> Maćkiem
    if (firstNameToLower.substring(firstName.length - 2) === 'ek') return firstName.slice(0, -2) + 'kiem'; // Marek -> Markiem, Zbyszek -> Zbyszkiem
    if (firstNameToLower.substring(firstName.length - 2) === 'eł') return firstName.slice(0, -2) + 'łem'; // Paweł -> Pawłem, Gaweł -> Gawłem
    if (firstNameToLower.charAt(firstName.length - 1) === 'k') return firstName + 'iem'; // Henryk -> Henrykiem, Patryk -> Patrykiem}
    if (firstNameToLower.charAt(firstName.length - 1) === 'a') return firstName.slice(0, -1) + 'ą'; // Natalia -> Natalią
    if (['o', 'e'].includes(firstNameToLower.charAt(firstNameToLower.length - 1))) return firstName; //Dante, Camilo
    if (firstNameToLower.charAt(firstName.length - 1) === 'y')  return firstName + 'm'; // Jerzy -> Jerzym, Johnny -> Johnnym

    return firstName + 'em'; // Marcin -> Marcinem, Tomasz => Tomaszem, Maciej -> Maciejem
}

export const nameInstrumental = (name, profileType, language) => {
    if (profileType !== 'personal')  return name; // commercial profiles

    return firstNameInstrumental(name, language);
}