import { combineReducers } from "redux";

import authorization from './authorizationReducer';
import user from './userReducer';
import flashMessage from "./flashMessageReducer";
import activity from "./activityReducer";
import participatingChallenge from "./participatingChallengeReducer";

const rootReducer = combineReducers({ authorization, user, flashMessage, activity, participatingChallenge });

export default rootReducer;