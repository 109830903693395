import {fetchMultimediaCollection} from "../../../redux/actions/profileActions";
import {connect} from "react-redux";
import React, {useEffect, useRef, useState} from "react";

const Multimedia = ({ dispatchFetchMultimediaCollection, profile }) => {
    const [multimediaCollection, setMultimediaCollection] = useState([]);
    const isSubscribed = useRef(true);

    useEffect(() => {
        isSubscribed.current = true;
        dispatchFetchMultimediaCollection(
            profile.uuid,
            (response) => isSubscribed.current ? setMultimediaCollection(response) : null,
            (errorResponse) => isSubscribed.current ? console.log(errorResponse) : null
        );

        return () => { isSubscribed.current = false };
    }, [profile, setMultimediaCollection]);

    if (multimediaCollection.length === 0) {
        return <></>
    }

    return <section>
        <h6>Gallery</h6>
        { multimediaCollection.map((item) => <div key={item.uuid} style={{display: "inline-block", margin: "5px", marginLeft: 0, marginTop: 0}}>
            <img src={item.url + '?size=200&token=' + JSON.parse(localStorage.getItem('authorization')).token}  style={{display: "inline-block", maxHeight:"90px"}} alt={'multimedia'} title={'multimedia'} />
        </div>) }
    </section>;
}

const mapDispatchToProps = (dispatch) => ({
    dispatchFetchMultimediaCollection: (profileUuid, onSuccess, onError) => dispatch(fetchMultimediaCollection(profileUuid, {page: 1, perPage: 8}, onSuccess, onError)),
});

export default connect(null, mapDispatchToProps)(Multimedia);