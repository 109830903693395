import React from 'react';
import {connect} from "react-redux";
import Avatar from "../../../../../_ui/Avatar/Avatar";
import {useTranslation} from "react-i18next";
import {joinChallenge} from "../../../../../../redux/actions/challengeActions";
import {addFlashMessage} from "../../../../../../redux/actions/flashMessageActions";

const ChallengeJoin = ({ uuid, team, player, participants, setChallenge, user, dispatchJoinChallengeAction, dispatchAddFlashMessage }) => {
    const { t } = useTranslation('Challenge');

    const handleJoinChallengeClicked = () => {
        dispatchJoinChallengeAction(
            uuid,
            { profile: { uuid: user.profile.uuid },team, player },
            (response) => setChallenge(response),
            (errorResponse) => dispatchAddFlashMessage(errorResponse.message),
        )
    }

    const isParticipant = () => {
        let isParticipant = false;
        Object.keys(participants).forEach((team, teamKey) => {
            Object.keys(team).forEach((key, playerKey) => {

                if (participants[teamKey][playerKey] !== undefined && participants[teamKey][playerKey].uuid === user.profile.uuid) {
                    isParticipant = true;
                }
            })
        });

        return isParticipant;
    }

    if (isParticipant()) {
        return <div className='participant join inactive'>
            <Avatar />
            <span>{ t('Challenge:invite') }</span>
        </div>
    }

    return <div className='participant join' onClick={ handleJoinChallengeClicked }>
        <Avatar />
        <span>{ t('Challenge:join') }</span>
    </div>
}

const mapDispatchToProps = dispatch => ({
    dispatchJoinChallengeAction: (uuid, data, onSuccess, onError) => dispatch(joinChallenge(uuid, data, onSuccess, onError)),
    dispatchAddFlashMessage: (message) => addFlashMessage(message, "error")
});

const mapStateToProps = state => ({
    user: state.user
});

export default connect(mapStateToProps, mapDispatchToProps)(ChallengeJoin);