import React, {useEffect, useState} from "react";
import { connect } from "react-redux";
import Cookies from "universal-cookie";
import { logout, refreshToken } from "../redux/actions/authActions";

const AuthKeeper = ({ globalLoading, setGlobalLoading, user, dispatchLogoutAction, dispatchRefreshTokenAction }) => {
    const [loading, setLoading] = useState(globalLoading);

    useEffect(() => {
        const handleSetLoading = (loading) => {
            setLoading(loading);
            setGlobalLoading(loading);
        }

        const cookies = new Cookies();
        const token = cookies.get('refresh_token');

        if (loading && user.isLoggedIn === null) {
            dispatchRefreshTokenAction(
                token,
                () => { handleSetLoading(false); },
                () => { dispatchLogoutAction(() => handleSetLoading(false), () => handleSetLoading(false)); }
            );
        }

        const interval = setInterval(() => {
            const cookies = new Cookies();
            const token = cookies.get('refresh_token');
            user.isLoggedIn && dispatchRefreshTokenAction(token)
        }, 60 * 1000);

        return () => clearInterval(interval);
    }, [
        user, setGlobalLoading, loading, dispatchRefreshTokenAction, dispatchLogoutAction
    ]);

    return <></>;
};

const mapStateToProps = (state) => ({
    user: state.user
});

const mapDispatchToProps = (dispatch) => ({
    "dispatchLogoutAction": (onSuccess, onError) => dispatch(logout(onSuccess, onError)),
    "dispatchRefreshTokenAction": (token, onSuccess, onError) => dispatch(
        refreshToken({ token: token }, onSuccess, onError)
    )
});

export default connect(mapStateToProps, mapDispatchToProps)(AuthKeeper);