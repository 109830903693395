import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { fetchProfile } from '../../redux/actions/profileActions';
import { fetchProfileActivities } from "../../redux/actions/activityActions";

import ActivityFeedContainer from '../../components/Activity/ActivityFeedContainer';

import ProfileLoader from './ProfileLoader';
import ProfileHeader from './components/ProfileHeader';

import './Profile.scss';
import ChangeDetailsForm from "./components/ChangeDetailsForm";
import Modal from "../../components/_ui/Modal/Modal";
import AvatarSettings from "./components/AvatarSettings";
import Multimedia from "./components/Multimedia";
import classNames from "classnames";
import Avatar from "../../components/_ui/Avatar/Avatar";

const Profile = ({ user, match, dispatchFetchProfile, dispatchFetchProfileActivities }) => {
    const [loading, setLoading] = useState(true);
    const [profile, setProfile] = useState(null);
    const [showAvatarSettings, setShowAvatarSettings] = useState(false);
    const [showProfileDetailsSettings, setShowProfileDetailsSettings] = useState(false);

    const isSubscribed = useRef(true);

    const maxActivities = 20;

    useEffect(() => {
        isSubscribed.current = true;
        setLoading(true);
        setProfile(null);
        dispatchFetchProfile(
            match.params.profilePath,
            (response) => isSubscribed.current ? handleProfileFetched(response) : null,
            (errorResponse) => isSubscribed.current ? handleProfileNotFetched(errorResponse) : null
        )

        return () => { isSubscribed.current = false };
    }, [setLoading, setProfile, match, dispatchFetchProfile]);

    const handleProfileFetched = (response) => {
        document.title = response.name + ' | Sportzzy';
        setProfile(response);
        setLoading(false);
    }

    const handleProfileNotFetched = (response) => {
        document.title = 'Profile not found | Sportzzy';
        setLoading(false);
    }

    const fetchMoreActivitiesDispatcher = (before, onSuccess, onError) => {
        dispatchFetchProfileActivities(
            profile.uuid,
            before,
            maxActivities,
            (response) => isSubscribed.current ? onSuccess(response) : null,
            (errorResponse) => isSubscribed.current ? onError(errorResponse) :null
        );
    }

    if (loading) return <ProfileLoader />;
    if (profile === null) {
        return (
            <div style={{ width: '100%', textAlign: 'center', paddingTop: '30%'}}><h3>Profile not found</h3></div>
        );
    }
    return (
        <div className='profile-page'>
            <ProfileHeader { ...profile } />
            { user.profiles.some(userProfile => userProfile.uuid === profile.uuid) &&
                <div className='profile-tools'>
                    <button onClick={() => setShowAvatarSettings(true)}>Change Avatar</button>
                    <button onClick={() => setShowProfileDetailsSettings(true)}>Change Profile Details</button>

                    <Modal
                        initialIsOpened={showAvatarSettings}
                        onCloseHandler={() => setShowAvatarSettings(false)}
                        title='Avatar Settings'
                    >
                        <AvatarSettings
                            onChanged = {(file) => setProfile({...profile, avatar: file})}
                            profile={ profile }
                            cancelHandler={() => setShowAvatarSettings(false)}
                        />
                    </Modal>

                    <Modal
                        initialIsOpened={showProfileDetailsSettings}
                        title='Change profile details'
                        onCloseHandler={() => setShowProfileDetailsSettings(false)}
                    >
                        <ChangeDetailsForm
                            initProfile={profile}
                            cancelHandler={() => setShowProfileDetailsSettings(false)}
                        />
                    </Modal>
                </div>
            }

            <div className='three-columns-page'>
                <div className='column'>
                    <div className='sticky-top'>
                        {/*<section className={'special'}>*/}
                        {/*    <Avatar className={'big'} image={ profile.avatar } label={ profile.name }/>*/}
                        {/*    <h6>{profile.name}</h6>*/}
                        {/*</section>*/}
                        <Multimedia profile={profile} />
                    </div>
                </div>

                <div className='main'>
                    <ActivityFeedContainer
                        profile={ profile }
                        maxActivities={ maxActivities }
                        fetchMoreActivitiesDispatcher={ fetchMoreActivitiesDispatcher }
                        publishActivityDispatcher
                    />
                </div>
                <div className='column' />
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    user: state.user,
});

const mapDispatchToProps = (dispatch) => ({
    dispatchFetchProfile: (path, onSuccess, onError) => dispatch(fetchProfile({ path: path }, onSuccess, onError)),
    dispatchFetchProfileActivities: (profileUuid, before, maxActivities, onError, onSuccess) =>
        dispatch(fetchProfileActivities(profileUuid, { before: before, perPage: maxActivities }, onError, onSuccess)),
});

Profile.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            profilePath: PropTypes.string.isRequired
        }).isRequired
    }).isRequired,
    dispatchFetchProfile: PropTypes.func.isRequired,
    dispatchFetchProfileActivities: PropTypes.func.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(Profile);