import React, {useState} from 'react';
import { connect } from "react-redux";

import { signIn } from '../../../../redux/actions/authActions';
import { useTranslation } from 'react-i18next';
import classNames from "classnames";
import { EMAIL } from "../../../../utils/regex";
import PropTypes from "prop-types";
import {NavLink} from "react-router-dom";

const SignInForm = ({ dispatchSignInAction }) => {
    const { t } = useTranslation(['translation', 'auth']);

    const [{ email, password }, setFormField] = useState({
        email: '', password: ''
    });
    const [errors, setErrors] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleFormFieldChange = ({ target: { name, value }}) => {
        setErrors({...errors, [name]: undefined});
        setFormField(prevState => ({ ...prevState, [name]: value }));
    }

    const setFormFieldError = (field, message) => setErrors({ ...errors, [field]: message });

    const validateEmail = () => {
        if (email === '') {
            setFormFieldError('email', t('landingPage:form.email.error.empty'));
        } else if (!EMAIL.test(String(email).toLowerCase())) {
            setFormFieldError('email', t('landingPage:form.email.error.invalid'));
        }
    }

    const validatePassword = () => {
        if (password === '') {
            setFormFieldError('password', t('landingPage:form.password.error.empty'));
        }
    }

    const handleSubmit = (event) => {
        event.preventDefault();

        setIsSubmitting(true);
        dispatchSignInAction(
            email.trim(),
            password.trim(),
            (errorResponse) => handleErrorResponse(errorResponse),
        );
    };

    const handleErrorResponse = errorResponse => {
        if (errorResponse.errors) setErrors(errorResponse.errors);
        setIsSubmitting(false);
    }

    return (
        <form method='post' action='/hi/' onSubmit={ handleSubmit } autoComplete='on'>
            <div className={classNames({'form-group': true, 'has-error': errors.email !== undefined})}>
                {/*<label for='signInEmail'>email</label>*/}
                <input
                    readOnly={isSubmitting}
                    required
                    id='signInEmail'
                    type='email'
                    name='email'
                    autoComplete='username'
                    placeholder={ t('landingPage:form.email.placeholder') }
                    value={ email }
                    maxLength='64'
                    className={ classNames({'has-error': errors.email !== undefined}) }
                    onChange={ handleFormFieldChange }
                    onBlur={ validateEmail }
                />
                {errors.email !== undefined ? <small className='hint'>{errors.email}</small> : ''}
            </div>

            <div className={classNames({'form-group': true, 'has-error': errors.password !== undefined})}>
                {/*<label htmlFor='signInPassword'>password</label>*/}
                <input
                    required
                    readOnly={isSubmitting}
                    id='signInPassword'
                    type={'password'}
                    name='password'
                    autoComplete='current-password'
                    placeholder={t('landingPage:form.password.placeholder')}
                    value={password}
                    maxLength='64'
                    className={classNames({'has-error': errors.password !== undefined})}
                    onChange={ handleFormFieldChange }
                    onBlur={ validatePassword }
                />
                {errors.password !== undefined ? <small className='hint'>{errors.password}</small> : ''}
            </div>

            <div className="form-group mb0">
                <input
                    value={ t('translation:signIn') }
                    type="submit"
                    className={classNames("btn primary", {loading: isSubmitting}) }
                    style={{ display: 'inline-block', width: 'inherit' }}
                    disabled={ isSubmitting }
                />
                <NavLink to='/hi/reset-password-request/'>forgot password?</NavLink>
            </div>
        </form>
    );
};

const mapDispatchToProps = dispatch => ({
    "dispatchSignInAction": (email, password, onError) =>
        dispatch(signIn({ email, password }, onError))
});

SignInForm.propTypes = {
    dispatchSignInAction: PropTypes.func.isRequired
};

export default connect(null, mapDispatchToProps)(SignInForm);