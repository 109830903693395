import React, {useState} from "react";

import { Input } from "../../../components/_ui/Form";

import './ChangeDetailsForm.scss';

const ChangeDetailsForm = ({ initProfile, cancelHandler }) => {
    const [profile, setProfile] = useState(initProfile);
    const [uploadedFile, setUploadedFile] = useState(null);

    const handleFormFieldChange = ({target: {name, value}}) => {
        setProfile(prevState => ({...prevState, [name]: value}));
    }

    const handleSaveClick = e => {
        e.preventDefault();
    }

    return <form className='change-profile-details-form'>
        <div className='change-profile-details-container'>
            <div className='change-details'>
                <label>Name</label>
                {profile.type === 'personal'
                 ? <div className='name'>
                        <Input value={ profile.name } autoComplete='off' onChange={ handleFormFieldChange } placeholder='First name' />
                        <Input value={ profile.name } autoComplete='off' onChange={ handleFormFieldChange } placeholder='Last name' />
                </div>
                 : <Input value={ profile.name } autoComplete='off' onChange={ handleFormFieldChange } placeholder='Name' />
                }
                <label>City</label>
                <Input value={ profile.city } autoComplete='off' placeholder='City' onChange={ handleFormFieldChange } />
                <label>Birthdate</label>
                <Input value={ profile.birthdate } autoComplete='off' placeholder='Birthdate' onChange={ handleFormFieldChange } />
            </div>
        </div>
        <div className='footer'>
            <button onClick={ (e) => cancelHandler(e) } className={'small'}>cancel</button>
            <button onClick={ (e) => handleSaveClick(e) } className='small primary'>save</button>
        </div>
    </form>
};

export default ChangeDetailsForm;