import * as constants from './../constants';

export const fetchActivities = (params, onSuccess, onError) => ({
    type: constants.API,
    payload: {
        method: 'GET',
        url: '/activities/',
        params,
        success: (response) => ({ type: constants.ACTIVITIES_FETCHED, payload: response}),
        postProcessSuccess: onSuccess,
        postProcessError: onError
    }
});

export const fetchProfileActivities = (profileUuid, params, onSuccess, onError) => ({
    type: constants.API,
    payload: {
        method: 'GET',
        url: '/profiles/' + profileUuid + '/activities/',
        params,
        success: (response) => ({ type: constants.PROFILE_ACTIVITIES_FETCHED, payload: response}),
        postProcessSuccess: onSuccess,
        postProcessError: onError
    }
});

export const publishActivity = (data, onSuccess, onError) => ({
    type: constants.API,
    payload: {
        method: 'POST',
        url: '/activities/',
        success: (response) => ({ type: constants.ACTIVITY_PUBLISHED, payload: response}),
        data,
        postProcessSuccess: onSuccess,
        postProcessError: onError
    }
});

export const deleteActivity = (uuid, onSuccess, onError) => ({
    type: constants.API,
    payload: {
        method: 'DELETE',
        url: '/activities/' + uuid + '/',
        success: (response) => ({ type: constants.ACTIVITY_DELETED, payload: response}),
        postProcessSuccess: onSuccess,
        postProcessError: onError
    }
});

export const commentActivity = (activityUuid, data, onSuccess, onError) => ({
    type: constants.API,
    payload: {
        method: 'POST',
        url: '/activities/' + activityUuid + '/comments/',
        success: (response) => ({ type: constants.ACTIVITY_COMMENTED, payload: response}),
        data,
        postProcessSuccess: onSuccess,
        postProcessError: onError
    }
});

export const fetchActivityComments = (activityUuid, data, onSuccess, onError) => ({
    type: constants.API,
    payload: {
        method: 'GET',
        url: '/activities/' + activityUuid + '/comments/',
        success: (response) => ({ type: constants.ACTIVITY_COMMENTS_FETCHED, payload: response}),
        data,
        postProcessSuccess: onSuccess,
        postProcessError: onError
    }
});



// const setActivities = (data) => ({
//     type: constants.SET_ACTIVITIES,
//     payload: data,
// });


