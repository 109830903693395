import React, {useEffect, useState} from "react";
import { connect } from "react-redux";
import {MapContainer, Marker, Polyline, Popup, TileLayer, LayersControl, LayerGroup} from 'react-leaflet'
import L from 'leaflet';


import Spinner from "../../components/_ui/Spinner/Spinner";
import {fetchChallenge, fetchChallengeTrainings} from "../../redux/actions/challengeActions";
import { useTranslation } from "react-i18next";
import { prepareTitle } from "../../utils/challengeUtils";

import 'leaflet/dist/leaflet.css';
import './Challenge.scss';

import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import Avatar from "../../components/_ui/Avatar/Avatar";
import moment from "moment/moment";

let DefaultIcon = L.icon({
    iconUrl: icon,
    shadowUrl: iconShadow
});

L.Marker.prototype.options.icon = DefaultIcon;

const Challenge = ({ match, dispatchFetchChallenge, dispatchFetchChallengeTrainings }) => {
    const [loading, setLoading] = useState(true);
    const [challenge, setChallenge] = useState({ uuid: null, name: null, avatar: null });
    const [challengeTrainings, setChallengeTrainings] = useState([]);

    const { t, i18n } = useTranslation(['challenges', 'disciplines']);

    useEffect(() => {
        const handleFetchChallengeSuccess = (response) => {
            setChallenge(response);
            setLoading(false);
        }

        setLoading(true);
        dispatchFetchChallenge(
            match.params.challengeUuid,
            (response) => handleFetchChallengeSuccess(response)
        );

        dispatchFetchChallengeTrainings(
            match.params.challengeUuid,
            (response) => setChallengeTrainings(response)
        );

    }, [setLoading, dispatchFetchChallenge, dispatchFetchChallengeTrainings, match]);

    const stringToColour = (str) => {
        let hash = 0;
        for (let i = 0; i < str.length; i++) {
            hash = str.charCodeAt(i) + ((hash << 5) - hash);
        }
        let colour = '#';
        for (let i = 0; i < 3; i++) {
            let value = (hash >> (i * 8)) & 0xFF;
            colour += ('00' + value.toString(16)).substr(-2);
        }

        return colour;
    }

    if (loading) {
        return <div style={{textAlign: 'center', padding: "100px"}}><Spinner /></div>;
    }

    document.title = prepareTitle(challenge, t, i18n) + '| Sportzzy';

    return (
        <React.Fragment>
            <div className="challenge header">
                { prepareTitle(challenge, t, i18n) }
            </div>
            { challenge.place &&
            <MapContainer center={[challenge.place.coordinate.lon, challenge.place.coordinate.lat]} zoom={15}>
                <TileLayer
                    attribution='&copy; <a href="https://osm.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                <Marker position={[challenge.place.coordinate.lon, challenge.place.coordinate.lat]}>
                    <Popup>
                        I am a pop-up!
                    </Popup>
                </Marker>


                <LayersControl position="topright">
                    { challengeTrainings.map((training) => <LayersControl.Overlay key={training.uuid} checked name={training.title}>
                            <Polyline pathOptions={{color: stringToColour(training.uuid), weight: 5}} positions={training.tracks.map((item) => [item.lat, item.lon])}>
                                <Popup>
                                    A pretty CSS3 popup. <br /> Easily customizable.
                                </Popup>
                            </Polyline>
                        </LayersControl.Overlay>

                    ) }

                </LayersControl>

            </MapContainer> }
            <div className='three-columns-page'>
                <div className='column'>
                    <section>
                    { challengeTrainings.length > 0 && <table>
                        <thead>
                            <tr>
                            <th></th><th></th><th>d</th><th>t</th><th>s</th>
                            {/*<th>c</th>*/}
                            </tr>
                        </thead>
                        <tbody>
                        { challengeTrainings.map((training) => <tr key={'t-' + training.uuid} style={{borderBottom: 'solid 1px #000'}}>
                                <td><Avatar className={'small'} /></td>
                                <td style={{verticalAlign: 'middle', fontSize: '12px', padding: '2px 5px'}}>{ moment(training.startedAt).format('LLL') }</td>
                                <td style={{verticalAlign: 'middle', fontSize: '12px', padding: '2px 5px'}}>{ training.metrics.distance > 1000 ? (training.metrics.distance / 1000).toFixed(3) + 'km' : training.metrics.distance.toFixed(2) + 'm'}</td>
                                <td style={{verticalAlign: 'middle', fontSize: '12px', padding: '2px 5px'}}>{ training.metrics.time }</td>
                                <td style={{verticalAlign: 'middle', fontSize: '12px', padding: '2px 5px'}}>{ training.metrics.averageSpeed }</td>
                                {/*<td style={{verticalAlign: 'middle', fontSize: '12px', padding: '2px 5px'}}>{ training.metrics.burnedCalories }</td>*/}
                            </tr>
                        ) }
                        </tbody>
                    </table> }
                    </section>
                </div>
                <div className='main'>
                </div>
                <div className='column' />
            </div>
        </React.Fragment>
    )
}

const mapStateToProps = (state) => ({ user: state.user});
const mapDispatchToProps = (dispatch) => ({
    "dispatchFetchChallenge": (challengeUuid, onSuccess) => dispatch(fetchChallenge(challengeUuid, onSuccess, (errorResponse) => console.log(errorResponse))),
    "dispatchFetchChallengeTrainings": (challengeUuid, onSuccess) => dispatch(fetchChallengeTrainings(challengeUuid, onSuccess, (errorResponse) => console.log(errorResponse))),
});

export default connect(mapStateToProps, mapDispatchToProps)(Challenge);