import React, { useEffect, useRef, useState } from "react";

import classNames from "classnames";
import moment from "moment";

import PublishActivityForm from "./Publish/PublishActivityForm";

import ActivityFeed from "./Feed/ActivityFeed";

import './ActivityFeed.scss';
import NoMoreContent from "./Feed/NoMoreContent";
import NoContent from "./Feed/NoContent";

const ActivityFeedContainer = ({ className, maxActivities, fetchMoreActivitiesDispatcher, publishActivityDispatcher, profile }) => {
    const [activities, setActivities] = useState(null);
    const [hasMore, setHasMore] = useState(true);
    const isSubscribed = useRef(true);

    useEffect(() => {
        return () => { isSubscribed.current = false };
    }, []);

    const fetchMoreActivities = () => {
        const handleFetchMoreActivitiesFailed = error => console.log(error);
        fetchMoreActivitiesDispatcher(
            activities ? activities.at(-1).occurredAt : moment().toISOString(true),
            (response) => isSubscribed.current ? handleMoreActivitiesFetched(response) : null,
            (errorResponse) => isSubscribed.current ? handleFetchMoreActivitiesFailed(errorResponse.message) : null,
        );
    }

    const handleMoreActivitiesFetched = fetchedActivities => {
        activities
            ? setActivities(activities => activities.concat(fetchedActivities))
            : setActivities(fetchedActivities);

        setHasMore(fetchedActivities.length >= maxActivities);
    }

    const handlePublishActivity = (activityData, onSuccess) => {
        const handleSuccess = (response) => {
            onSuccess(response);
            setActivities([response].concat(activities));
        };

        publishActivityDispatcher(
            activityData,
            (response) => isSubscribed.current ? handleSuccess(response) : null,
            (errorResponse) => isSubscribed.current ? console.log(errorResponse.message) : null,
        );
    }

    return <div className={ classNames('activity-feed-container', className) }>
        <PublishActivityForm publishActivityHandler={ handlePublishActivity } />
        {/*<PublishActivityForm appendActivity={ handlePublishActivity } />*/}
        <ActivityFeed activities={ activities } fetchMore={ hasMore ? fetchMoreActivities : null } />
        { activities && activities.length > 0 && !hasMore && <NoMoreContent profile={ profile } /> }
        { activities && activities.length === 0 && !hasMore && <NoContent profile={ profile } /> }
    </div>
}

export default ActivityFeedContainer;