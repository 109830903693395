import React, {useLayoutEffect, useRef, useState} from 'react';
import classNames from "classnames";
import {useTranslation} from "react-i18next";
import InContentChallenge from "./Widget/InContentChallenge";

const Text = ({ content, theme }) => {
    const { t } = useTranslation('ActivityFeed');
    const [collapsed, setCollapsed] = useState(true);
    const [showMore, setShowMore] = useState(false);
    const ref = useRef();

    const replaceChallengeLinks = content => {
        const domain = window.location.protocol + '//' + window.location.host;
        const challengeLink = content.match(domain + '/challenges/([a-z0-9-]+)([/]?)');
        if (challengeLink !== null) {
            const parts = content.split(challengeLink[0]);
            let newParts = [];
            for (let i = 0; i < parts.length; i++) {
                newParts.push(parts[i])
                if (i < parts.length - 1)
                    newParts.push(<InContentChallenge key={ 'challenge-link-' + i } challengeUuid={ challengeLink[1] } />)
            }

            content = newParts;
        }

        return content;
    }

    useLayoutEffect(() => {
        if (ref.current.clientHeight < ref.current.scrollHeight) {
            setShowMore(true);
        }
    }, [ref]);

     return (
         <div ref={ ref } className={ classNames('text', {collapsed: collapsed, 'has-more': showMore}, theme) }>
             { replaceChallengeLinks(content) }
             { showMore && <div className='show-more' onClick={ () => setCollapsed(!collapsed) }>
                 { collapsed ? t('ActivityFeed:activity.showMore') : t('ActivityFeed:activity.showLess') }
             </div> }
         </div>
     );
}

export default Text;