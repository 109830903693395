import React, { useEffect, useRef, useState } from 'react';
import classNames from "classnames";
import Avatar from "../../../components/_ui/Avatar/Avatar";

const ProfileHeader = ({ name, avatar }) => {
    const [rolledUp, setRolledUp] = useState(false);
    const headerRef = useRef(null);

    useEffect(() => {
        const headerHeight = headerRef.current.clientHeight;
        const handleScroll = () => { setRolledUp(headerHeight - window.scrollY < 66) };
        window.addEventListener("scroll", handleScroll);

        return () => { window.removeEventListener("scroll", handleScroll) };
    }, []);

    return <div ref={ headerRef } className={ classNames('profile-header', { 'rolled-up': rolledUp }) }>
        <div className='container'>
            <Avatar image={ avatar } label={ name } className={ classNames({ big: !rolledUp, large: rolledUp }) } />
            <h2>{ name }</h2>
        </div>
    </div>
}

export default ProfileHeader;