import React from "react";
import {Link} from "react-router-dom";
import Ico from "../../_ui/Ico/Ico";
import DropDown from "../../_ui/DropDown/DropDown";

const Messages = () => {
    return (
        <DropDown className={ 'messages' } toggle={ <Link to='/'><Ico className='envelope' /></Link> }>
            messages
        </DropDown>
    );
}

export default Messages;