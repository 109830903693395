import React, {useEffect} from "react";
import {Link, NavLink} from "react-router-dom";
import { useTranslation } from "react-i18next";

import '../../_ui/Button/Button.scss';
import classNames from "classnames";
import HamburgerToggle from "../../_ui/DropDown/Toggle/HamburgerToggle";
import DropDown from "../../_ui/DropDown/DropDown";

const GuestNavBar = ({className, changeLanguage, handleHideMenu}) => {
    const { t } = useTranslation(["translation"]);

    useEffect(() => {
        document.addEventListener("click", handleHideMenu);

        return () => {
            document.removeEventListener("click", handleHideMenu);
        };
    }, [handleHideMenu]);

    return (
        <DropDown className={ 'guest-nav' } toggle={ <Link to='/' style={{ marginRight: 0 }}><HamburgerToggle /></Link> }>
            <nav className={ classNames(className, 'guest') }>
                <ul>
                    <li>
                        <NavLink exact to="/hi/" className='btn' activeClassName="primary">{ t('translation:signIn') }</NavLink>
                    </li>
                    <li>
                        <NavLink to="/hi/sign-up/" className='btn' activeClassName="primary">{ t('translation:signUp') }</NavLink>
                    </li>
                    <li className='section-title small-devices'>
                        <h6>Other languages</h6>
                    </li>
                    <li>
                        <button className='light' onClick={ changeLanguage }>
                            { localStorage.getItem('language') === 'pl'
                                ? <React.Fragment>english<span className='not-small-devices'>?</span></React.Fragment>
                                : <React.Fragment>polski<span className='not-small-devices'>?</span></React.Fragment>
                            }
                        </button>
                    </li>
                </ul>
            </nav>
        </DropDown>
    );
}

export default GuestNavBar;