import React from "react";

import './Spinner.scss';
import {useTranslation} from "react-i18next";

const Spinner = () => {
    const { t } = useTranslation(['translation']);

    return (
        <React.Fragment>
            <div className="spinner">
                <div />
            </div>
            <div>{ t('translation:loader', { brand: t('translation:brand') }) }</div>
        </React.Fragment>
    );
}

export default Spinner;