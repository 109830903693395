import React from "react";
import { Container } from "../../../../components/_ui/Grid";
import { Slogan } from "../../../../components/_ui/Branding";
import { Route, Switch } from "react-router-dom";
import { SignInForm, SignUpForm } from "../Form";
import { useTranslation } from "react-i18next";
import ResetPasswordRequest from "../Form/ResetPasswordRequest";

const FormSection = () => {
    const { t } = useTranslation(["landingPage"]);

    // const handleLoaded = _ => {
    //     window.grecaptcha.ready(_ => {
    //         window.grecaptcha
    //             .execute("6LeJ4qQdAAAAAMdrr8xQ-lstX-nqUumCrceIbNH3", { action: "homepage" })
    //             .then(token => {
    //                 // ...
    //             })
    //     })
    // }

    // useEffect(() => {
    //     // Add reCaptcha
    //     const script = document.createElement("script")
    //     script.src = "https://www.google.com/recaptcha/api.js?render=6LeJ4qQdAAAAAMdrr8xQ-lstX-nqUumCrceIbNH3"
    //     script.addEventListener("load", handleLoaded)
    //     document.body.appendChild(script)
    // }, [])

    return (
        <section className="landing-page-form-section">
            <video autoPlay muted playsInline loop id="myVideo">
                <source src="/video/rollerblades.mp4" type="video/mp4" />
            </video>

            <Container className="text-center">
                <Slogan firstLine={ t('landingPage:slogan.firstLine') } secondLine={ t('landingPage:slogan.secondLine') } />
                <div className="panel">
                    <Switch>
                        <Route exact path="/hi/" component={ SignInForm } />
                        <Route path="/hi/sign-up/" component={ SignUpForm } />
                        <Route path="/hi/reset-password-request/" component={ ResetPasswordRequest } />
                    </Switch>
                </div>
            </Container>
            {/*<div*/}
            {/*    className="g-recaptcha"*/}
            {/*    data-sitekey="6LeJ4qQdAAAAAMdrr8xQ-lstX-nqUumCrceIbNH3"*/}
            {/*    data-size="invisible"*/}
            {/*/>*/}
        </section>
    );
}

export default FormSection;