import React from 'react';
import classNames from "classnames";

import './style.scss';

const Panel = ({ className, style, children }) => {
    return <div className={ classNames('ui-panel', className) } style={ style }>
        { children }
    </div>
}

export default Panel;