import React, {useEffect, useRef, useState} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrash} from "@fortawesome/free-solid-svg-icons";
import {Panel} from "../../UI";
import {fetchDisciplines} from "../../../redux/actions/challengeActions";
import {connect} from "react-redux";
import {useTranslation} from "react-i18next";
import { Select } from "../../_ui/Form";
import DatePicker from "react-datepicker";
import Input from "../../_ui/Form/Input";

const ChallengeForm = ({ challenge, setChallenge, setShowChallenge, dispatchFetchDisciplines }) => {
    const { t } = useTranslation('disciplines');
    const [disciplines, setDisciplines] = useState([]);
    const isSubscribed = useRef(true);

    useEffect(() => {
        dispatchFetchDisciplines((response) => isSubscribed ? setDisciplines(response) : null);

        return () => { isSubscribed.current = false };
    }, [dispatchFetchDisciplines, challenge]);

    return <div className='challenge-form'>
        <div className='slim'>
            <span onClick={() => { setShowChallenge(false); setChallenge(null) }}>
                <FontAwesomeIcon style={{ fontSize: '12px' }} icon={ faTrash } />
            </span>
        </div>
        <div className='wild'>
            <Panel>
                    <div className='section'>
                        <h5>{ t('Challenge:challenge.header') }</h5>
                        <div className='form-input'>
                            <Select style={{ maxWidth: '100%'}}
                                    placeholder={ 'Select discipline...' }
                                    options={ disciplines.map((discipline) => { return { value: discipline.name, label: t('disciplines:' + discipline.name) } }) }
                                    onChange={ val => setChallenge({ ...challenge, discipline: val }) }
                            />
                        </div>
                        <div className='form-input'>
                            <DatePicker
                                className='input'
                                selected={ challenge && challenge.date ? challenge.date : null }
                                onChange={(date) => setChallenge({ ...challenge, date: date })}
                                minDate={ new Date() }
                                placeholderText={ t('Challenge:date.placeholder') }
                                timeFormat="HH:mm"
                                timeIntervals={30}
                                showTimeSelect
                                dateFormat="d MMMM yyyy H:mm"
                            />
                        </div>
                        <div className='form-input'>
                            <Input
                                value={challenge && challenge.duration ? challenge.duration : 60}
                                name='duration'
                                dataType='number'
                                placeholder='duration'
                                onChange={ e => setChallenge({ ...challenge, duration: e.target.value }) }
                            />
                        </div>
                    </div>
                    <div className='section'>
                        <h5>{ t('Challenge:participants.header') }</h5>
                        <div className='form-input-group'>
                            {/*beginner', 'intermediate', 'pro*/}
                            <label className="radio">
                                <input type="radio" name="level" value="beginner" onChange={ () => setChallenge({ ...challenge, level: 'beginner' }) } />
                                beginner
                            </label>
                            <label className="radio">
                                <input type="radio" name="level" value="intermediate"  onChange={ () => setChallenge({ ...challenge, level: 'intermediate' }) } />
                                intermediate
                            </label>
                            <label className="radio">
                                <input type="radio" name="level" value="pro" onChange={ () => setChallenge({ ...challenge, level: 'pro' }) } />
                                pro
                            </label>
                        </div>
                    </div>
                    <div className='section'>
                        <h5>{ t('Challenge:place.header') }</h5>
                    </div>

            </Panel>
        </div>
    </div>
}

const mapDispatchToProps = dispatch => ({
    dispatchFetchDisciplines: (onSuccess, onError) =>
        dispatch(fetchDisciplines(onSuccess, onError))
});

export default connect(null, mapDispatchToProps)(ChallengeForm);