import React from "react";
import { connect } from "react-redux";
import FlashMessage from "./FlashMessage";

import "./FlashMessage.scss";

const FlashMessageList = ({flashMessages}) => {

    return (
        <div className="flash-message-list-container">
        { flashMessages.map((message) => <FlashMessage key={message.id} id={message.id} message={message.message} type={message.type} />) }
        </div>
    )
}

const mapStateToProps = (state) => ({
    flashMessages: state.flashMessage
});

export default connect(mapStateToProps, null)(FlashMessageList);