import React, {useEffect, useRef, useState} from "react";
import CommentForm from "./CommentForm";
import { fetchActivityComments } from "../../../../../redux/actions/activityActions";
import {connect} from "react-redux";
import Comment from "./Comment";
import { useTranslation } from "react-i18next";
import CommentPlaceholder from "./CommentPlaceholder";
import Pusher from "react-pusher";

const CommentSection = ({ commentsCount, increaseCommentsCount, activityUuid, dispatchFetchActivityComments }) => {
    const { t } = useTranslation('ActivityFeed');
    const [comments, setComments] = useState([]);
    const [loading, setLoading] = useState(true);
    const isSubscribed = useRef(true);

    useEffect(() => {
        dispatchFetchActivityComments(
            activityUuid,
            1, 20, //todo handle pagination
            (response) => isSubscribed.current ? handleActivityCommentsFetched(response) : null,
            (errorResponse) => setLoading(false)
        );

        return () => { isSubscribed.current = false; }
    }, [activityUuid, setComments, dispatchFetchActivityComments]);

    const handleActivityCommentsFetched = (response) => {
        setComments(response);
        setLoading(false);
    };

    const appendComment = (comment) => {
        if (comments.find(c => c.uuid === comment.uuid) === undefined) {
            setComments(comments => [comment].concat(comments));
            increaseCommentsCount();
        }
    }

    return <div className="comments-section">
        <Pusher
            channel={ 'activities.' + activityUuid + '.comments' }
            event='ActivityCommentWasAdded'
            onUpdate={ (response) => appendComment(response)}
        />
        <CommentForm appendComment={ appendComment } activityUuid={ activityUuid } />
        { comments.map((comment) => <Comment key={ comment.uuid } { ...comment } />) }
        { loading && <CommentPlaceholder commentsCount={commentsCount} /> }
        { !loading && comments.length === 0 && <div className='no-comments'>{t('ActivityFeed:comments.noContent')}</div>}
    </div>
}

const mapDispatchToProps = (dispatch) => ({
    dispatchFetchActivityComments: (activityUuid, page, perPage, onSuccess, onError) =>
        dispatch(fetchActivityComments(
            activityUuid,
            { page, perPage },
            onSuccess,
            onError
        )
    ),
});

export default connect(null, mapDispatchToProps)(CommentSection);