import React, {useState} from 'react';
import ChallengeHeader from "./Challenge/ChallengeHeader";
import ChallengeParticipants from "./Challenge/ChallengeParticipants";
import Pusher from "react-pusher";
import classNames from "classnames";
import moment from "moment/moment";

const Challenge = ({ challenge }) => {
    const [stateChallenge, setChallenge] = useState(challenge);
    const finished = moment(challenge.date).diff(moment()) <= 0;

    return <div className={classNames('challenge', { finished: finished})}>
        <ChallengeHeader challenge={ stateChallenge } />
        <ChallengeParticipants { ...stateChallenge } setChallenge={ setChallenge } finished={finished} />
        <Pusher
            channel={'challenges.' + challenge.uuid}
            event="ChallengeWasUpdated"
            onUpdate={ (response) => setChallenge(response)}
        />
    </div>
}

export default Challenge;