import React from 'react';
import {Panel} from "../../../../UI";

const CommentPlaceholder = ({commentsCount}) => {
    const placeholder = <div><Panel className='comment placeholder'>
        <div className='author placeholder'>
            <div className={'placeholder'} style={{ marginRight: "15px", backgroundColor: "#e1e1e1", width: '25px', height: '25px', borderRadius: '50%' }} />
            <span className='name placeholder' style={{ display: 'block', backgroundColor: "#e1e1e1", width:"180px", height: '16px' }} />
            <span className='name placeholder' style={{ marginRight: "15px", backgroundColor: "#e1e1e1", width:"100px", height: '16px' }} />
            <span className='date placeholder' />
        </div>
        <div className='content'>
            <span className='placeholder' style={{ display: 'inline-block', backgroundColor: "#e1e1e1", width:"100px", height: '20px' }} />
            <span className='placeholder' style={{ display: 'inline-block', marginRight: "5px", backgroundColor: "#e1e1e1", width:"20px", height: '20px' }} />
            <span className='placeholder' style={{ display: 'inline-block', marginRight: "5px", backgroundColor: "#e1e1e1", width:"50px", height: '20px' }} />
            <span className='placeholder' style={{ display: 'inline-block', marginRight: "5px", backgroundColor: "#e1e1e1", width:"80px", height: '20px' }} />
        </div>
    </Panel></div>;

    if (commentsCount === 0) return placeholder;

    let placeholders = [];

    if (commentsCount === 0) {
        return placeholder;
    }

    while(commentsCount-- > 0) {
        placeholders.push(<React.Fragment key={commentsCount}>{ placeholder }</React.Fragment>);
    }

    return placeholders;
}

export default CommentPlaceholder;