import React from "react";
import TextareaAutosize from "react-autosize-textarea";
import Avatar from "../../../../_ui/Avatar/Avatar";
import {connect} from "react-redux";
import {commentActivity} from "../../../../../redux/actions/activityActions";
import {useTranslation} from "react-i18next";

const CommentForm = ({ appendComment, activityUuid, user, dispatchCommentActivity }) => {
    const { t } = useTranslation('ActivityFeed')

    const onKeyDown = (e) => {
        if(e.keyCode === 13 && e.shiftKey === false) {
            e.preventDefault();
            dispatchCommentActivity(
                activityUuid,
                {
                    author: user.profile,
                    comment: e.target.value,
                },
                // (response) => appendComment(response)
            );
            e.target.value = '';
        }
    }

    return <div className='comment-form'>
        <Avatar className='small' image={ user.profile.avatar } />
        <TextareaAutosize placeholder={ t('ActivityFeed:comments.form.placeholder') } onKeyDown={ (e) => onKeyDown(e) } className='input' />
    </div>
}

const mapStateToProps = state => ({
    user: state.user
});

const mapDispatchToProps = (dispatch) => ({
    dispatchCommentActivity: (activityUuid, data, onSuccess, onError) =>
        dispatch(commentActivity(
                activityUuid,
                data,
                onSuccess,
                onError
            )
        ),
});

export default connect(mapStateToProps, mapDispatchToProps)(CommentForm);