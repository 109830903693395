import React, {useEffect, useState} from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Panel } from "../../UI";
import Activity from "./Activity/Activity";
import moment from "moment";
import {fetchProfile} from "../../../redux/actions/profileActions";
import ActivityLoader from "./Activity/ActivityLoader";

const NoContent = ({ profile, user, dispatchFetchProfile }) => {
    const { t } = useTranslation('ActivityFeed');
    const [sportzzyProfile, setSportzzyProfile] = useState(null);

    useEffect(() => {
        let isSubscribed = true;

        dispatchFetchProfile(
            'sportzzy',
            (response) => isSubscribed ? setSportzzyProfile(response) : null
        );

        return () => { isSubscribed = false };
    }, [dispatchFetchProfile]);

    if (!sportzzyProfile) {
        return <ActivityLoader />
    }

    if (profile !== undefined) {
        return <Panel className='no-content'>
            { user.profiles.find(userProfile => profile.uuid === userProfile.uuid) === undefined
                ? t(
                    'ActivityFeed:noContent.profile',
                    { name: profile.type === 'commercial' ? profile.name : profile.name.split(' ')[0] }
                )
                : t('ActivityFeed:noContent.owner')
            }
        </Panel>
    }

    const activity = {
        uuid: null,
        occurredAt: moment().toISOString(),
        author: sportzzyProfile,
        contents: {
            text: {
                content: t('ActivityFeed:noContent.wall', { name: user.profile.name.split(' ')[0] }),
                theme: ""
            },
            multimedia: [{
                'url': 'https://multimedia.sportzzy.com/file/c9da/32bd492c-de63-4e6d-9965-32bd2a4cda4d.jpg',
                'type': 'image/jpeg'
            }],
            challenge: null
        },
        tags: ['general', 'sportzzy'],
        comments: {
            off: true,
            total: 0,
        }
    }

    return <Panel className='activity-feed'><Activity key={ activity.uuid } { ...activity } /></Panel>
}

const mapStateToProps = (state) => ({
    user: state.user
});

const mapDispatchToProps = (dispatch) => ({
    'dispatchFetchProfile': (path, onSuccess) => dispatch(fetchProfile({path: path}, onSuccess, (errorResponse) => console.log(errorResponse))),
});

export default connect(mapStateToProps, mapDispatchToProps)(NoContent);