import React, {useEffect, useState} from 'react';
import {confirmEmail, logout} from "../../redux/actions/authActions";
import {connect} from "react-redux";
import {Redirect} from "react-router-dom";
import {addFlashMessage} from "../../redux/actions/flashMessageActions";

export const Confirm = ({ match, dispatchConfirmEmailAction, dispatchLogoutAction, dispatchAddFlashMessageAction }) => {
    const { token } = match.params;
    const [isSubmitting, setIsSubmitting] = useState(true);

    useEffect(() => {
        const handleErrorResponse = errorResponse => {
            dispatchAddFlashMessageAction(errorResponse.message, "error");
            setIsSubmitting(false);
        }

        const handleSuccessResponse = response => {
            setIsSubmitting(false);
            dispatchAddFlashMessageAction(response.message, "success");
            dispatchLogoutAction();
        }

        let isSubscribed = true;

        dispatchConfirmEmailAction(
            token,
            (response) => isSubscribed ? handleSuccessResponse(response) : null,
            (errorResponse) => isSubscribed ? handleErrorResponse(errorResponse) : null,
        );

        return () => (isSubscribed = false);
    }, [token, dispatchConfirmEmailAction, dispatchLogoutAction, dispatchAddFlashMessageAction]);

    return (
        isSubmitting ? <React.Fragment>Verifying</React.Fragment> : <Redirect to='/hi' />
    );
}

const mapDispatchToProps = dispatch => ({
    "dispatchConfirmEmailAction": (token, onSuccess, onError) => dispatch(confirmEmail(token, onSuccess, onError)),
    "dispatchLogoutAction": dispatch(logout()),
    "dispatchAddFlashMessageAction": (message, messageType) => dispatch(addFlashMessage(message, messageType))
});

export default connect(null, mapDispatchToProps)(Confirm);