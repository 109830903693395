import React from 'react';
import { prepareTitle } from "../../../../../../utils/challengeUtils";
import { useTranslation } from "react-i18next";
import moment from "moment";
import classNames from "classnames";
import {
    faCalendar,
    faMapMarkerAlt,
    faMedal,
    faMoneyBillAlt,
    faStopwatch,
    faUsers
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {Link} from "react-router-dom";

const ChallengeHeader = ({ challenge }) => {
    const { t, i18n } = useTranslation(['Challenge', 'challenges', 'disciplines']);

    const calculateDuration = minutes => {
        let duration = [];
        const durationDays = Math.floor(minutes / (60 * 24));
        if (durationDays) duration.push(durationDays + 'd');

        const durationHours = Math.floor((minutes - durationDays * 60 * 24) / 60);
        if (durationHours) duration.push(durationHours + 'H');

        const durationMinutes = Math.floor(minutes % 60);
        if (durationMinutes) duration.push(durationMinutes + 'min');

        return duration.join(' ')
    }

    const calculateParticipantsSummary = discipline => {
        if (discipline.teams === 1) return discipline.teamMembers === null ? '∞' : discipline.teamMembers;

        return discipline.teamMembers + ' vs ' + discipline.teamMembers;
    }

    return <div className={ classNames('challenge-header', 'bg-' + challenge.discipline.name) }>
        <h5 className='title'><Link to={'/challenges/' + challenge.uuid + '/'}>{ prepareTitle(challenge, t, i18n) }</Link></h5>
        <div className='meta'>
            <div style={{ width: '100%', display: 'block'}}>
                <div>
                    <FontAwesomeIcon icon={ faCalendar } />
                    { moment(challenge.date, null, i18n.language).format('LLL') }
                </div>
                <div><FontAwesomeIcon icon={ faStopwatch} />{ calculateDuration(challenge.duration) }</div>
                {challenge.place && <div><FontAwesomeIcon icon={ faMapMarkerAlt } /> { challenge.place.name }, { challenge.place.city }</div> }
            </div>
            <div>
                <div>
                    <FontAwesomeIcon icon={ faMedal } />
                    { challenge.level.map((level) => t('Challenge:level.' + level)).join(', ') }
                </div>
                <div><FontAwesomeIcon icon={ faUsers } />{ calculateParticipantsSummary(challenge.discipline) }</div>
                <div><FontAwesomeIcon icon={ faMoneyBillAlt } />50 PLN / cort</div>
            </div>
        </div>
    </div>
}

export default ChallengeHeader;