import React, { useState, useCallback } from 'react'
import Cropper from 'react-easy-crop'
import { getCroppedImg, getRotatedImage } from '../../utils/canvas';
import './styles.scss'

const Crop = ({ initialImage, onCropped }) => {
    const [crop, setCrop] = useState({ x: 0, y: 0 })
    const [zoom, setZoom] = useState(1)
    const onCropComplete = useCallback(async (croppedArea, croppedAreaPixels) => {
        try {
            const croppedImage = await getCroppedImg(
                initialImage,
                croppedAreaPixels
            )

            onCropped(croppedImage);
        } catch (e) {
            console.error(e)
        }

        // onCropped();
    }, [])
    return (
            <div className="crop-container">
                <Cropper
                    image={initialImage}
                    crop={crop}
                    zoom={zoom}
                    cropShape="round"
                    aspect={1}
                    onCropChange={setCrop}
                    onCropComplete={onCropComplete}
                    onZoomChange={setZoom}
                    showGrid={false}
                    objectFit="contain"
                    classes={{ cropAreaClassName: 'cropper' }}
                />
            </div>

    )
}

export default Crop;
