import React, {useEffect} from 'react';

const Topic = ({ match }) => {
    useEffect(() => {
        let isSubscribed = true;

        isSubscribed && console.log('[GET] /topics/' + match.params.tag + '/activity/');

        return () => { isSubscribed = false }
    }, [match.params.tag]);

    return <div>Topic { match.params.tag }</div>
}

export default Topic;