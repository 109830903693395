import moment from "moment";
import {nameInstrumental} from "./nameUtils";

export const prepareTitle = (challenge, t, i18n) => {

    const timeOfDay = datetime => {
        const hour = moment(datetime).hour();
        if (hour > 5 && hour < 12) return 'morning';
        if (hour >= 12 && hour < 18) return "afternoon";
        if (hour >= 18 && hour < 23) return "evening";

        return 'night';
    }

    const name = nameInstrumental(challenge.organizer.name, challenge.organizer.type, i18n.language);
    const title = t('challenges:title',
        {
            weekDay: '$t(challenges:times.weekDay.' + moment(challenge.date).isoWeekday() + ')',
            dayTime: '$t(challenges:times.dayTime.' + timeOfDay(challenge.date) + ')',
            what: '$t(disciplines:' + challenge.discipline.name + ')',
            name: name,
            with: i18n.language === 'pl'
            && ['s', 'ś', 'z'].includes(name.toLowerCase().charAt(0))
            && name.toLowerCase().substring(0, 2) !== 'sz'
            && name.toLowerCase().charAt(name.length - 1) !== 'ą'
                ? 'ze' : '$t(challenges:with)' //ze Sławkiem, ze Zbyszkiem, z Zuzanną, z Szymonem, ze Światowidem
        }
    );

    return title.charAt(0).toUpperCase() + title.slice(1);
}

export const prepareShortTitle = (challenge, user, t, i18n) => {
    const name = challenge.organizer.uuid === user.profile.uuid
        ? '$t(challenges:youInstrumental)'
        : nameInstrumental(challenge.organizer.name, challenge.organizer.type, i18n.language);

    const title = t('challenges:shortTitle',
        {
            what: '$t(disciplines:' + challenge.discipline.name + ')',
            name: name,
            with: i18n.language === 'pl'
            && ['s', 'ś', 'z'].includes(name.toLowerCase().charAt(0))
            && name.toLowerCase().substring(0, 2) !== 'sz'
            && name.toLowerCase().charAt(name.length - 1) !== 'ą'
                ? 'ze' : '$t(challenges:with)' //ze Sławkiem, ze Zbyszkiem, z Zuzanną, z Szymonem, ze Światowidem
        }
    );

    return title.charAt(0).toUpperCase() + title.slice(1);
}