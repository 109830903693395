import * as constants from './../constants';
import jwt_decode from "jwt-decode";
import { Cookies } from "react-cookie";

export const signUp = (data, onSuccess, onError) => ({
    type: constants.API,
    payload: {
        method: 'POST',
        url: '/auth/sign-up/',
        data,
        success: (response) => ({ type: constants.REQUIRE_EMAIL_CONFIRMATION, payload: response}),
        postProcessSuccess: onSuccess,
        postProcessError: onError
    }
});

export const confirmEmail = (token, onSuccess, onError) => ({
    type: constants.API,
    payload: {
        method: 'POST',
        url: '/auth/confirm/' + token + '/',
        postProcessSuccess: onSuccess,
        postProcessError: onError
    }
});

export const signIn = (data, onError) => ({
    type: constants.API,
    payload: {
        method: 'POST',
        url: '/auth/sign-in/',
        data,
        success: (response) => (setAuthorization(response)),
        // postProcessSuccess: () => { window.location.href = '/' },
        postProcessError: onError
    }
});

export const refreshToken = (data, onSuccess, onError) => ({
    type: constants.API,
    payload: {
        method: 'POST',
        url: '/auth/refresh/',
        data,
        success: (response) => (setAuthorization(response)),
        error: (response) => (resetAuthorization(response)),
        postProcessSuccess: onSuccess,
        postProcessError: onError
    }
});

export const requestPasswordResetCode = (data, onSuccess, onError) => ({
    type: constants.API,
    payload: {
        method: 'POST',
        url: '/auth/reset-password-request/',
        data,
        postProcessSuccess: onSuccess,
        postProcessError: onError
    }
});

export const resetPassword = (data, onSuccess, onError) => ({
    type: constants.API,
    payload: {
        method: 'POST',
        url: '/auth/reset-password/',
        data,
        postProcessSuccess: onSuccess,
        postProcessError: onError
    }
});

export const logout = (onSuccess, onError) => ({
    type: constants.API,
    payload: {
        method: 'DELETE',
        url: '/auth/',
        success: (response) => (resetAuthorization(response)),
        error: (response) => (resetAuthorization(response)),
        postProcessSuccess: onSuccess,
        postProcessError: onError
    }
});

const setAuthorization = (data) => {
    let token = {
        token: data.token,
        expires: jwt_decode(data.token).exp,
    };

    localStorage.setItem('authorization', JSON.stringify(token));

    const cookies = new Cookies();
    // cookies.set('refresh_token', data.refreshToken, { path: '/', maxAge: 60 * 60 * 24, httpOnly: true, secure: true, sameSite: true });
    cookies.set('refresh_token', data.refreshToken, { path: '/', maxAge: 60 * 60 * 24, sameSite: true, secure: process.env.REACT_APP_API_URL === 'production' });

    return { type: constants.SET_AUTHORIZATION, payload: token}
}

const resetAuthorization = () => {
    localStorage.removeItem('authorization');
    document.cookie = 'refresh_token=;path=/;expires=Thu, 01 Jan 1970 00:00:01 GMT;';

    return { type: constants.RESET_AUTHORIZATION };
}