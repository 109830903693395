export const API = 'API';
export const SET_AUTHORIZATION = 'SET_AUTHORIZATION';
export const RESET_AUTHORIZATION = 'RESET_AUTHORIZATION';

export const SET_USER_INFO = 'SET_USER_INFO';
export const RESET_USER_INFO = 'RESET_USER_INFO';

export const REQUIRE_EMAIL_CONFIRMATION = 'REQUIRE_EMAIL_CONFIRMATION';
export const ADD_FLASH_MESSAGE = 'ADD_FLASH_MESSAGE';
export const DELETE_FLASH_MESSAGE = 'DELETE_FLASH_MESSAGE';

export const FETCH_PROFILE = 'FETCH_PROFILE';
export const FETCH_PROFILES = 'FETCH_PROFILES';

export const FETCH_PROFILE_FOLLOWERS = 'FETCH_PROFILE_FOLLOWERS';
export const SET_ACTIVITIES = 'SET_ACTIVITIES';

export const ACTIVITY_PUBLISHED = 'ACTIVITY_PUBLISHED';
export const ACTIVITY_DELETED = 'ACTIVITY_DELETED';
export const ACTIVITIES_FETCHED = 'ACTIVITIES_FETCHED';
export const PROFILE_ACTIVITIES_FETCHED = 'PROFILE_ACTIVITIES_FETCHED';
export const ACTIVITY_COMMENTED = 'ACTIVITY_COMMENTED';
export const ACTIVITY_COMMENTS_FETCHED = 'ACTIVITY_COMMENTS_FETCHED';

export const PARTICIPATE_CHALLENGES = 'PARTICIPATE_CHALLENGES';
export const FETCH_PARTICIPATING_CHALLENGES = 'FETCH_PARTICIPATING_CHALLENGES';
