import React from 'react';

import Challenge from "./Content/Challenge";
import Multimedia from "./Content/Multimedia";
import Text from "./Content/Text";
import Training from "./Content/Training";

const ActivityContent = ({ text, challenge, multimedia, training }) => {
    return <div className='content'>
        { text && <Text {...text } /> }
        { challenge && <Challenge challenge={ challenge } /> }
        { multimedia && <Multimedia items={ multimedia } /> }
        { training && <Training items={ training } /> }
    </div>
}

export default ActivityContent;