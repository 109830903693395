import React, {useEffect} from "react";

import {Link, NavLink} from "react-router-dom";
import Avatar from "../../_ui/Avatar/Avatar";

import {useTranslation} from "react-i18next";
import DropDown from "../../_ui/DropDown/DropDown";
import HamburgerToggle from "../../_ui/DropDown/Toggle/HamburgerToggle";
import Notifications from "./Notifications";
import Messages from "./Messages";

const UserNavBar = ({ user, dispatchLogoutAction, changeLanguage, handleHideMenu }) => {
    const { t } = useTranslation(["translation", "navbar"]);

    useEffect(() => {
        document.addEventListener("click", handleHideMenu);

        return () => {
            document.removeEventListener("click", handleHideMenu);
        };
    }, [handleHideMenu]);

    const handleLogout = (e) => {
        e.preventDefault();
        dispatchLogoutAction()
    }

    return (
            <React.Fragment>
                <Link to={ '/' + user.profile.path + '/' }>
                    <Avatar image={ user.profile.avatar } label={ user.profile.name } link={ '/' + user.profile.path + '/' } />
                    <span className='user-name'>{ user.profile.name }</span>
                </Link>
                <Notifications />
                <Messages />

                <DropDown className={ 'user-nav' } toggle={ <Link to='/' style={{ marginRight: 0 }}><HamburgerToggle /></Link> }>
                    <nav>
                        <ul>
                            <li className='section-title'>
                                <h6>{t('navbar:user.profiles.title')}</h6>
                            </li>
                            { user.profiles.map((profile) => <li key={profile.uuid}>
                                <NavLink exact to={ '/' + profile.path + '/' } style={{ display: 'flex', alignItems: 'center', textDecoration: 'none'}}>
                                    <Avatar image={ profile.avatar } className='small' label={ profile.name } link={ '/' + profile.path + '/' } />
                                    <span className='user-name'>{ profile.name }</span>
                                </NavLink>
                            </li>) }
                            <li className='divider-bottom'>
                                <Link to='/profiles/create/'>{ t('navbar:user.profiles.create') }</Link>
                            </li>
                            <li>
                                <NavLink to='/settings/'>{ t('navbar:user.settings') }</NavLink>
                            </li>
                            <li className='divider-bottom'>
                                <NavLink to='/hi/' onClick={ handleLogout }>{ t('navbar:user.logout') }</NavLink>
                            </li>
                            <li>
                                <Link to='/' onClick={ changeLanguage }>{ localStorage.getItem('language') === 'pl'
                                    ? 'english'
                                    : 'polski'
                                }</Link>
                            </li>
                        </ul>
                    </nav>
                </DropDown>

            </React.Fragment>
    );
}

export default UserNavBar;