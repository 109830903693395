import React, {useState, cloneElement, useEffect} from 'react';
import classNames from "classnames/bind";

import "./DropDown.scss"

const DropDown = ({ toggle, children, className }) => {
    const [opened, setOpened] = useState(false);

    useEffect(() => {
        const close = () => opened && setOpened(false);

        document.addEventListener("click", close);

        return () => {
            document.removeEventListener("click", close);
        };
    }, [opened]);

    const handleToggleClicked = (event) => {
        event.preventDefault();
        setOpened(!opened);
    }

    return (
        <div className={ classNames('dropdown', className, { opened: opened}) }>
            { toggle && cloneElement(toggle, { onClick: handleToggleClicked, className: classNames('toggle') }) }
            <div className='content'>
                { children }
            </div>
        </div>
    )
}

export default DropDown;