import * as constants from './../constants';

export default function participatingChallengeReducer(state = [], action) {

    switch(action.type) {
        case constants.FETCH_PARTICIPATING_CHALLENGES:
            return action.payload;
        case constants.PARTICIPATE_CHALLENGES:
            let newState = [...state, action.payload];
            return newState.sort(( a, b ) => {
                if ( a.date > b.date )  return -1;
                if ( a.date < b.date ) return 1;
                return 0;
            });
        case constants.ACTIVITY_PUBLISHED:
            if (action.payload.contents.challenge) {
                let newState = [...state, action.payload.contents.challenge];

                return newState.sort(( a, b ) => {
                    if ( a.date > b.date )  return -1;
                    if ( a.date < b.date ) return 1;
                    return 0;
                });
            }


            return state;
        case constants.RESET_AUTHORIZATION:
            return [];
        default:
            return state;
    }
}