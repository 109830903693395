import React from 'react';

import { Panel } from "../../UI";

import Activity from "./Activity/Activity";
import ActivityLoader from "./Activity/ActivityLoader";

import './ActivityFeed.scss';
import classNames from "classnames";

const ActivityFeed = ({ activities, fetchMore }) => {
    if (activities && activities.length === 0) {
        return <></>;
    }

    return <Panel className={classNames('activity-feed', {'placeholder': fetchMore && !activities})}>
        { activities && activities.map((activity) => <Activity key={ activity.uuid } { ...activity } />) }
        { fetchMore && <ActivityLoader fetchMore={ fetchMore } /> }
    </Panel>
}

export default ActivityFeed;