import React from "react";
import classNames from "classnames/bind";

const Column = (props) => {
    const sectionClass = {
        column: true,
        "column-main": props.main
    };

    return (
        <div className={classNames(sectionClass, props.className)} style={props.style}>
            { props.children }
        </div>
    )
}

export default Column;