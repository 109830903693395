import i18n from "i18next";
import Backend from "i18next-xhr-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

i18n
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        fallbackLng: "en",
        debug: false,
        react: {
            useSuspense: false,
        },
        interpolation: {
            escapeValue: false
        },
        // backend: {
        //     loadPath: '/public/locales/{{ns}}.{{lng}}.json'
        // },
        transSupportBasicHtmlNodes: true, // allow <br/> and simple html elements in translations
        transKeepBasicHtmlNodesFor: ['br', 'strong', 'i', 'span']
    });

export default i18n;