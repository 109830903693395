import * as constants from './../constants';

const defaultState = {
    token: null,
    expires: null,
};

const authorization = localStorage.getItem('authorization');
const INITIAL_STATE = authorization ? JSON.parse(authorization) : defaultState;

export default function authorizationReducer(state = INITIAL_STATE, action) {
    switch(action.type) {
        case constants.SET_AUTHORIZATION:
            return { ...action.payload };
        case constants.RESET_AUTHORIZATION:
            return defaultState;
        default:
            return state;
    }
}