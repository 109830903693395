import React from "react";
import {NavLink, Route, Switch} from "react-router-dom";
import { useTranslation } from "react-i18next";
import {Column, Row} from "../../components/_ui/Grid";

const About = () => {
    const { t } = useTranslation(["translation", "about"]);

    return (
        <React.Fragment>
            <Row style={{ display: "flex", padding: "50px 0" }}>
                <Column className="col-s-3" style={{ }}>
                    <ul style={{ background: "#fff" }}>
                        <li>
                            <NavLink style={{ width: "100%", textAlign: "left", padding: "5px 10px", margin: "0" }} exact to="/about/terms-of-service/" className='btn none' activeClassName="active">{ t('about:termsOfService.menuLink') }</NavLink>
                        </li>
                        <li>
                            <NavLink style={{ width: "100%", textAlign: "left", padding: "5px 10px", margin: "0" }} exact to="/about/privacy-policy/" className='btn none' activeClassName="active">{ t('about:privacyPolicy.menuLink') }</NavLink>
                        </li>
                    </ul>
                </Column>

                <Column className="col-s-9" style={{padding: "0 20px" }}>
                    <Switch>
                        <Route exact path="/about/terms-of-service/"><h2>{ t('about:termsOfService.header') }</h2><div>{ t('about:termsOfService.content') }</div></Route>
                        <Route path="/about/privacy-policy/"><h2>{ t('about:privacyPolicy.header') }</h2><div>{ t('about:privacyPolicy.content') }</div></Route>
                    </Switch>
                </Column>
            </Row>
        </React.Fragment>
    );
}

export default About;