import React, {useEffect, useRef} from 'react';
import {connect} from "react-redux";
import {fetchActivities, publishActivity} from "../../../../redux/actions/activityActions";
import ActivityFeedContainer from "../../../../components/Activity/ActivityFeedContainer";

export const ActivityFeedSection = ({ dispatchFetchActivities, dispatchPublishActivity }) => {
    const isSubscribed = useRef(true);
    const maxActivities = 20;

    useEffect(() => {
        return () => { isSubscribed.current = false };
    }, []);

    const fetchMoreActivitiesDispatcher = (before, onSuccess, onError) => {
        dispatchFetchActivities(
            before,
            maxActivities,
            (response) => isSubscribed.current ? onSuccess(response) : null,
            (errorResponse) => isSubscribed.current ? onError(errorResponse) :null
        );
    }

    return (

        <section className="wall-column wall-activity-feed">
            <ActivityFeedContainer
                maxActivities={ maxActivities }
                fetchMoreActivitiesDispatcher={ fetchMoreActivitiesDispatcher }
                publishActivityDispatcher={ dispatchPublishActivity }
            />

            {/*<ActivityFeed dispatchFetchActivities={dispatchFetchActivities} />*/}
        </section>
    );
}

const mapDispatchToProps = (dispatch) => ({
    dispatchFetchActivities: (before, maxActivities, onSuccess, onError) =>
        dispatch(fetchActivities({ before: before, perPage: maxActivities }, onSuccess, onError)),
    dispatchPublishActivity: (data, onSuccess, onError) =>
        dispatch(publishActivity(data, onSuccess, onError))
});

export default connect(null, mapDispatchToProps)(ActivityFeedSection);