import React from "react";
import classNames from "classnames/bind";

import './style.scss';

const Input = (props) => {
    const defaultClassNames = {
        input: true,
        // small: props.small !== undefined && props.small,
        // medium: props.medium !== undefined && props.medium,
        // big: props.big !== undefined && props.big
    };

    return (
        <input
            autoComplete={ props.autoComplete }
            className={ classNames(defaultClassNames, props.className) }
            name={ props.name }
            onBlur={ props.onBlur}
            onChange={ props.onChange }
            onClick={ props.onClick }
            onFocus={ props.onFocus }
            onKeyUp={ props.onKeyUp }
            placeholder={ props.placeholder }
            ref={ props.customRef }
            style={ props.style }
            value={ props.value }
        />
    )
}

export default Input;