import React, {useState} from 'react';
import {useTranslation} from "react-i18next";
import classNames from "classnames";
import {EMAIL} from "../../../../utils/regex";
import {requestPasswordResetCode, resetPassword} from "../../../../redux/actions/authActions";
import PropTypes from "prop-types";
import {connect} from "react-redux";

const ResetPasswordForm = ({dispatchRequestPasswordResetCode, dispatchResetPassword}) => {
    const {t} = useTranslation(["translation", "auth"]);
    const [formSent, setFormSent] = useState(false);
    const [{ email, password, code }, setFormField] = useState({email: '', password: '', code: ''});
    const [errors, setErrors] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleFormFieldChange = ({ target: { name, value }}) => {
        setErrors({...errors, [name]: undefined});
        setFormField(prevState => ({ ...prevState, [name]: value }));
    }

    const setFormFieldError = (field, message) => setErrors({ ...errors, [field]: message });

    const validateEmail = () => {
        if (email === '') {
            setFormFieldError('email', t('landingPage:form.email.error.empty'));
        } else if (!EMAIL.test(String(email).toLowerCase())) {
            setFormFieldError('email', t('landingPage:form.email.error.invalid'));
        }
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        setIsSubmitting(true);
        if (formSent) {
            dispatchResetPassword(
                { "email": email.trim(), "code": code, "password": password },
                (response) => handleSuccessResponse(response),
                (errorResponse) => handleErrorResponse(errorResponse),
            )
        } else {
            dispatchRequestPasswordResetCode(
                email.trim(),
                (response) => handleSuccessResponse(response),
                (errorResponse) => handleErrorResponse(errorResponse),
            )
        }
    };

    const handleErrorResponse = errorResponse => {
        if (errorResponse.errors) setErrors(errorResponse.errors);
        setIsSubmitting(false);
    }

    const handleSuccessResponse = response => {
        setIsSubmitting(false);
        setFormSent(true);
    }

    return <form method='post' onSubmit={ handleSubmit }>
        <div>Send me reset password link</div>
        <div className={classNames({'form-group': true, 'has-error': errors.email !== undefined})}>
            <input
                readOnly={formSent || isSubmitting}
                required
                id='resetPasswordEmail'
                type='email'
                name='email'
                autoComplete='username'
                placeholder={ t('landingPage:form.email.placeholder') }
                value={ email }
                maxLength='64'
                className={ classNames({'has-error': errors.email !== undefined}) }
                onChange={ handleFormFieldChange }
                onBlur={ validateEmail }
            />
            {errors.email !== undefined ? <small className='hint'>{errors.email}</small> : ''}
        </div>

        { formSent && <div className={classNames({'form-group': true, 'has-error': errors.password !== undefined})}>
            <input
                readOnly={ isSubmitting }
                required
                id='newPassword'
                type='password'
                name='password'
                autoComplete='new-password'
                placeholder={ t('landingPage:form.newPassword.placeholder') }
                value={ password }
                maxLength='64'
                className={ classNames({'has-error': errors.password !== undefined}) }
                onChange={ handleFormFieldChange }
                onBlur={ validateEmail }
            />
            {errors.email !== undefined ? <small className='hint'>{errors.password}</small> : ''}
        </div> }

        { formSent && <div className={classNames({'form-group': true, 'has-error': errors.code !== undefined})}>
            <input
                readOnly={ isSubmitting }
                required
                id='code'
                type='text'
                name='code'
                autoComplete='off'
                placeholder={ t('landingPage:form.code.placeholder') }
                value={ code }
                maxLength='5'
                className={ classNames({'has-error': errors.code !== undefined}) }
                onChange={ handleFormFieldChange }
                onBlur={ validateEmail }
            />
            {errors.code !== undefined ? <small className='hint'>{errors.code}</small> : ''}
        </div> }

        <div className="form-group mb0">
            { formSent
                ? <input
                    value={t('translation:resetPassword')}
                    type="submit"
                    className={classNames("btn primary", {loading: isSubmitting})}
                    style={{display: 'inline-block', width: 'inherit'}}
                    disabled={isSubmitting}
                />
                : <input
                    value={t('translation:sendResetPasswordCode')}
                    type="submit"
                    className={classNames("btn primary", {loading: isSubmitting})}
                    style={{display: 'inline-block', width: 'inherit'}}
                    disabled={isSubmitting}
                />
            }
        </div>
    </form>
}

const mapDispatchToProps = dispatch => ({
    "dispatchRequestPasswordResetCode": (email, onSuccess, onError) =>
        dispatch(requestPasswordResetCode({ email }, onSuccess, onError)),
    "dispatchResetPassword": (data, onSuccess, onError) =>
        dispatch(resetPassword(data, onSuccess, onError))
});

ResetPasswordForm.propTypes = {
    dispatchRequestPasswordResetCode: PropTypes.func.isRequired
};

export default connect(null, mapDispatchToProps)(ResetPasswordForm);