import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {connect} from "react-redux";
import {followProfile} from "../../../../../redux/actions/profileFollowerAction";
import {Link} from "react-router-dom";
import Avatar from "../../../../../components/_ui/Avatar/Avatar";
import {fetchProfiles} from "../../../../../redux/actions/profileActions";
import { Input } from "../../../../../components/_ui/Form";

export const FollowersSearch = ({user, dispatchFetchFindProfiles, dispatchFollowProfile}) => {
    const { t } = useTranslation(["followers"]);
    const [foundProfiles, setFoundProfiles] = useState([]);
    const [searchTerm, setSearchTerm] = useState('')

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            if (searchTerm.length > 2) {
                dispatchFetchFindProfiles(
                    searchTerm,
                    (response) => setFoundProfiles(response),
                    (errorResponse) => console.log(errorResponse),
                );
            } else if (searchTerm.length === 0) {
                setFoundProfiles([]);
            }
        }, 500)

        return () => (clearTimeout(delayDebounceFn))
    }, [searchTerm, dispatchFetchFindProfiles])

    const handleSendInvitation = (event) => {
        event.preventDefault();

        let profiles = foundProfiles;
        let profileIndex = profiles.findIndex(el => el.uuid === event.target.getAttribute('data-profile-uuid'));
        profiles[profileIndex].followerStatus = profiles[profileIndex].type === 'personal' ? 'invitation_sent' : 'confirmed';

        dispatchFollowProfile(
            event.target.dataset.profileUuid,
            user.profile.uuid,
            () => setFoundProfiles([...profiles])
        );
    };

    return (
        <div>
            <Input
                autoComplete="off"
                name='profileName'
                onKeyUp={(e) => setSearchTerm(e.target.value)}
                placeholder={ t('followers:section.search.placeholder') }
            />
            <div className={'results'}>
                <ul>
                    {foundProfiles.map((profile) =>
                        <li key={profile.uuid} style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '10px 0'}}>
                            <div>
                                <Link style={{display: 'flex', alignItems: 'center', textDecoration: 'none', fontSize: '18px'}} to={ '/' + profile.path + '/'} title={profile.name} >
                                    <Avatar image={profile.avatar} className='small' style={{marginRight: '5px'}} /> { profile.name }
                                </Link>
                            </div>
                            { profile.uuid === user.profile.uuid
                                ? <div style={{textAlign: 'right', textDecoration: 'none', fontSize: '16px'}}><span style={{ fontSize: '75%', padding: '0 10px'}}>you</span></div>
                                : profile.followerStatus !== 'blocked' && <div style={{textAlign: 'right', textDecoration: 'none', fontSize: '16px'}}>
                                { profile.followerStatus === null && profile.uuid !== user.profile.uuid
                                    && <Link style={{paddingLeft: '10px', paddingRight: '10px'}} className='btn small light' to={'/'} data-profile-uuid={ profile.uuid } onClick={ handleSendInvitation }>
                                        {profile.type === 'personal' ? 'invite' : 'follow'}
                                    </Link>
                                }
                                { profile.followerStatus === 'confirmed' && <span style={{ fontSize: '75%', padding: '0 10px'}}>following</span> }
                                { profile.followerStatus === 'awaiting_confirm' && <Link style={{paddingLeft: '10px', paddingRight: '10px'}} className='btn small primary' to={'/'} data-profile-uuid={ profile.uuid } onClick={ () => console.log('confirmed') }>confirm</Link> }
                                { profile.followerStatus === 'invitation_sent' && <span style={{ fontSize: '75%', padding: '0 10px'}}>invitation sent</span> }
                            </div> }
                        </li>
                    )}
                </ul>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => ({ user: state.user});
const mapDispatchToProps = (dispatch) => ({
    dispatchFetchFindProfiles: (searchKey, onSuccess) => dispatch(fetchProfiles({search: {name: searchKey}}, onSuccess, (errorResponse) => console.log(errorResponse))),
    dispatchFollowProfile: (profileUuid, followerProfileUuid, onSuccess) => dispatch(followProfile(profileUuid, { follower: {uuid:followerProfileUuid }}, onSuccess))
});

export default connect(mapStateToProps, mapDispatchToProps)(FollowersSearch);