import * as constants from './../constants';

export default function activityReducer(state = { last: null, hasMore: true, items: [], lastPayload : [] }, action) {

    switch(action.type) {
        case constants.SET_ACTIVITIES:
            let payload = action.payload.filter(({id: id1}) => !state.lastPayload.some(({id: id2}) => id2 === id1));
            return {
                ...state,
                hasMore: payload.length > 0,
                items: [...state.items,...payload],
                last: payload.length > 0 ? payload[payload.length-1].occuredAt : state.last,
                lastPayload : action.payload,
                filtered : payload
            };
        default:
            return state;
    }
}