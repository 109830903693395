import React, {useState} from "react";
import { connect } from "react-redux";
import UserNavBar from "./NavBar/UserNavBar";
import GuestNavBar from "./NavBar/GuestNavBar";

import { Brand } from "../_ui/Branding";

import './Header.scss';
import classNames from "classnames";
import i18n from "i18next";


const Header = ({ user, dispatchLogoutAction }) => {
    const [showMenu, setShowMenu] = useState(false);

    const changeLanguage = (e) => {
        const lang = localStorage.getItem('language') === 'pl' ? 'en' : 'pl';
        e.preventDefault();
        i18n.changeLanguage(lang);
        localStorage.setItem("language", lang);
        document.documentElement.lang = lang;
    }

    const handleHideMenu = () => {
        if (showMenu) {
            setShowMenu(false);
        }
    }

    return (
        <header>
            <div className='container' style={{alignItems: 'center'}}>
                <Brand main size='medium' href='/' />
                <div className={'header-navbar'}>
                    { user.isLoggedIn
                        ? <UserNavBar
                            className={ classNames({ 'toggle-opened': showMenu })}
                            user={ user }
                            dispatchLogoutAction={ dispatchLogoutAction }
                            changeLanguage={ changeLanguage }
                            handleHideMenu={ handleHideMenu }
                        />
                        : <GuestNavBar
                            className={ classNames({ 'toggle-opened': showMenu })}
                            changeLanguage={ changeLanguage }
                            handleHideMenu={ handleHideMenu }
                        />
                    }
                </div>
            </div>
        </header>
    );
}

const mapStateToProps = (state) => ({
    user: state.user
});

export default connect(mapStateToProps, null)(Header);
