import * as constants from './../constants';
import jwt_decode from "jwt-decode";

const defaultState = {
    uuid: null,
    profile: {},
    profiles: [],
    isLoggedIn: null
};

const authorization = JSON.parse(localStorage.getItem('authorization'));
const INITIAL_STATE = authorization ? {...jwt_decode(authorization.token), ...{ isLoggedIn: null}} : defaultState;

export default function userReducer(state = INITIAL_STATE, action) {
    switch(action.type) {
        case constants.SET_AUTHORIZATION:
            return { ...{...jwt_decode(action.payload.token), ...{ isLoggedIn: true}} };
        case constants.RESET_AUTHORIZATION:
            return defaultState;
        default:
            return state;
    }
}