import React, {useState} from "react";
import {Input, Select} from "../../components/_ui/Form";

const UI = () => {
    const [inputValue, setInputValue] = useState('');
    const [selectValue, setSelectValue] = useState('');
    const selectOptions = [
        {value: 1, label: "Option 1"},
        {value: 2, label: "Option 2"},
        {value: 3, label: "Option 3"},
        {value: 4, label: "Option 4"},
        {value: 5, label: "Option 5"}
    ];
    return (

            <table style={{ width: '100%' }}>
                <thead>
                <tr><td colSpan='3' style={{ textAlign: 'center' }}>Form.Input</td></tr>
                <tr><td /><td>Input</td><td>Select</td></tr>
                </thead>
                <tbody>
                <tr>
                    <td>small</td>
                    <td><Input placeholder='Type something...' value={ inputValue } onChange={ e => setInputValue(e.target.value) } className='small' /></td>
                    <td><Select options={ selectOptions } placeholder='Select something...' value={ selectValue } onChange={ value => setSelectValue(value) } className='small' /></td>
                </tr>
                <tr>
                    <td>default</td>
                    <td><Input placeholder='Type something...' value={ inputValue } onChange={ e => setInputValue(e.target.value) } /></td>
                    <td><Select style={{ width: '300px'}} options={ selectOptions } placeholder='Select something...' value={ selectValue } onChange={ value => setSelectValue(value) } /></td>
                </tr>
                <tr>
                    <td>medium</td>
                    <td><Input placeholder='Type something...' value={ inputValue } onChange={ e => setInputValue(e.target.value) } className='medium' /></td>
                    <td><Select options={ selectOptions } placeholder='Select something...' value={ selectValue } onChange={ value => setSelectValue(value) } className='medium' /></td>
                </tr>
                <tr>
                    <td>big</td>
                    <td><Input placeholder='Type something...' value={ inputValue } onChange={ e => setInputValue(e.target.value) } className='big' /></td>
                    <td><Select options={ selectOptions } placeholder='Select something...' value={ selectValue } onChange={ value => setSelectValue(value) } className='big' /></td>
                </tr>
                </tbody>
            </table>

    );
}

export default UI;