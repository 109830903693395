import React, {useState} from 'react';

import { Panel } from "../../UI";

import './PublishActivityForm.scss';
import Avatar from "../../_ui/Avatar/Avatar";
import TextareaAutosize from "react-autosize-textarea";
import {connect} from "react-redux";
import classNames from "classnames";

import { faImages, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ChallengeForm from "./ChallengeForm";

const PublishActivityForm = ({ user, publishActivityHandler }) => {
    const [isActive, setIsActive] = useState(false);
    const [multimedia, setMultimedia] = useState([]);
    const [challenge, setChallenge] = useState(null);
    const [showChallenge, setShowChallenge] = useState(false);

    const defaultText = { content: "", theme: "" };
    const [text, setText] = useState(defaultText);

    const [publishAs, setPublishAs] = useState(user.profile);

    const onFileChanged = (e) => {
        setMultimedia([]);

        Array.from(e.target.files).forEach(file => {
            let fileReader = new FileReader();
            fileReader.readAsDataURL(file);
            fileReader.onload = (event) => {
                setMultimedia(multimedia => [...multimedia, event.target.result]);
            }
        });
    }

    const onPublishActivityClicked = () => {
        publishActivityHandler(
            {
                author: {
                    uuid: publishAs.uuid
                },
                contents: {
                    text: text.content ? text : null,
                    challenge: challenge,
                    multimedia: multimedia
                },
                tags: [challenge ? challenge.discipline : 'general'],
                places: [],
                relations: []
            },
            () => { setMultimedia([]); setText(defaultText); setShowChallenge(false); setChallenge(null); setIsActive(false) }
        )
    }

    return <Panel className='activity-publish-form'>
        <div className='basic-activity'>
            <div className='slim'>
                <Avatar image={ publishAs.avatar } label={ publishAs.name } />
            </div>
            <div className='wild'>
                <TextareaAutosize
                    value={ text.content }
                    placeholder='Publish activity'
                    maxRows={ 5 }
                    className={ classNames('input') }
                    onChange={ e => setText({...text, content: e.target.value }) }
                    onFocus={ e => setIsActive(true) }
                />
                {user.profiles.length > 1 &&
                    <div style={{textAlign: "right"}}>
                        <span style={{fontSize: "75%"}}>Publish as: </span>
                        {user.profiles.map((profile) =>
                            <div key={profile.uuid} onClick={() => setPublishAs(profile)}
                                 style={{display: "inline-block", cursor:"pointer"}} title={profile.name}>
                                <Avatar style={{width: "20px", height: "20px"}} image={profile.avatar}
                                        label={profile.name}/>
                            </div>
                        )}
                    </div>
                }
                <div>
                </div>
            </div>
        </div>
        { isActive && <>
            { multimedia.length > 0 && <div className='multimedia-thumbnails'>
                <div className='slim'><span onClick={() => setMultimedia([])}><FontAwesomeIcon style={{ fontSize: '12px' }} icon={ faTrash } /></span></div>
                <div className='wild'>
                    <Panel>
                        { multimedia.map((m, index) => <img key={ index } src={ m } alt='preview' />) }
                    </Panel>
                </div>
            </div> }

            { showChallenge && <ChallengeForm challenge={ challenge } setChallenge={ setChallenge } setShowChallenge={ setShowChallenge } /> }

            <div className='activity-actions'>
                <div className="upload-btn-wrapper">
                    <button className='small'><FontAwesomeIcon style={{ fontSize: '12px' }} icon={ faImages } /></button>
                    <input type="file" multiple accept='image/*'  onChange={ e => onFileChanged(e) } />
                </div>
                <button className='small' onClick={ () => setShowChallenge(true) }>Add challenge</button>
                <button className='publish-button small primary' onClick={ () => onPublishActivityClicked() }>Publish{user.profile.uuid !== publishAs.uuid && <> as {publishAs.name}</>}</button>
            </div>
        </> }
    </Panel>
}

const mapStateToProps = state => ({
    user: state.user
});

export default connect(mapStateToProps, null)(PublishActivityForm);