import React from 'react';
import ChallengeParticipant from "./ChallengeParticipant";
import ChallengeJoin from "./ChallengeJoin";
import classNames from "classnames";
import Avatar from "../../../../../_ui/Avatar/Avatar";

const ChallengeParticipants = ({ uuid, discipline, setChallenge, participants, finished }) => {
    const prepareTeams = (discipline, participants) => {
        let teams = [];

        for (let teamIndex = 0; teamIndex < discipline.teams; teamIndex++ ) {
            let players = [];
            const members = discipline.teamMembers === null
                ? Object.keys(participants[teamIndex]).length + 1
                : discipline.teamMembers;

            for (let playerIndex = 0; playerIndex < members; playerIndex++ ) {

                const player = participants[teamIndex] ? participants[teamIndex][playerIndex] ?? undefined : undefined;

                let playerKey = 'player-' + teamIndex + '-' + playerIndex;

                if (player !== undefined) {
                    players.push(<ChallengeParticipant
                        key={ playerKey }
                        { ...player }
                    />);
                } else if (!finished) {
                    players.push(<ChallengeJoin key={ playerKey }
                        uuid={ uuid }
                        team={ teamIndex }
                        player={ discipline.teamMembers === null ? null : playerIndex }
                        setChallenge={ setChallenge }
                        participants={ participants }
                    />);
                } else {
                    players.push(<div key={ playerKey } className='participant join finished'>
                        <Avatar />

                    </div>);
                }
            }

            teams.push(<div key={ 'team-' + teamIndex } className='team'>{ players }</div> );
        }

        return teams;
    }

    return (
        <div className={ classNames(
            'participants',
            discipline.name,
            { 'players-any': discipline.teamMembers === null }
        ) }>
            { prepareTeams(discipline, participants) }
        </div>
    )
}

export default ChallengeParticipants;