import React, {useEffect, useState} from "react";
import classNames from "classnames";
import {deleteFlashMessage} from "../../redux/actions/flashMessageActions";
import {connect} from "react-redux";

const FlashMessage = ( { id, message, type, dispatchDeleteFlashMessageAction } ) => {
    const [opacity, setOpacity] = useState(1);
    const flashMessageClass = {
        'flash-message': true,
        'fading-in': true,
    };

    useEffect(() => {
            const timerId = setTimeout(() => {
                setTimeout(() => {
                    dispatchDeleteFlashMessageAction(id);
                }, 50);
                setOpacity(0);
            }, 5000);

            return () => clearTimeout(timerId);
        },
        [id, dispatchDeleteFlashMessageAction]
    );

    const closeMessage = (e) => {
        e.preventDefault();
        setOpacity(0);
        setTimeout(() => {
            dispatchDeleteFlashMessageAction(id);
        }, 50);
    }

    return (
        <div className={classNames(flashMessageClass, type)} style={{opacity: opacity}}>
            <div className="inner">{ message }</div>
            <a onClick={closeMessage} href="/" className="close">&#x2715;</a>
        </div>
    )
}

const mapDispatchToProps = dispatch => ({
    "dispatchDeleteFlashMessageAction": (id) => dispatch(deleteFlashMessage(id))
});

export default connect(null, mapDispatchToProps)(FlashMessage);