import React from "react";
import classNames from "classnames/bind";

import defaultImage from './assets/avatar.png';
import './Avatar.scss';

const Avatar = ({image, label, style, className}) => {

    const setDefault = (e) => {
        e.target.src = defaultImage;
    }

    return (
        <img
            className={ classNames({ avatar: true }, className) }
            src={ image ? image : defaultImage }
            alt={ label ? label : 'Avatar' }
            style={ style ?? null }
            onError={ setDefault }
        />
    );
}

export default Avatar;