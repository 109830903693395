import React from 'react';

const ProfileHeaderLoader = () => {
    return <div className='profile-header placeholder'>
        <div className='avatar-placeholder big' />
        <span className='h2-placeholder'>
            <span style={{ width: '120px' }} /> <span style={{ width: '160px' }} />
        </span>
    </div>
}

export default ProfileHeaderLoader;