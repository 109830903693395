import * as constants from './../constants';

export const fetchProfile = (params, onSuccess, onError) => ({
    type: constants.API,
    payload: {
        method: 'GET',
        url: '/profiles/',
        params,
        success: (response) => ({ type: constants.FETCH_PROFILE, payload: response}),
        postProcessSuccess: onSuccess,
        postProcessError: onError
    }
});

export const fetchProfiles = (params, onSuccess, onError) => ({
    type: constants.API,
    payload: {
        method: 'GET',
        url: '/profiles/',
        params,
        success: (response) => ({ type: constants.FETCH_PROFILES, payload: response}),
        postProcessSuccess: onSuccess,
        postProcessError: onError
    }
});

export const removeAvatar = (profileUuid, onSuccess, onError) => ({
    type: constants.API,
    payload: {
        method: 'DELETE',
        url: '/profiles/' + profileUuid + '/settings/avatar/',
        postProcessSuccess: onSuccess,
        postProcessError: onError
    }
});

export const changeAvatar = (profileUuid, data, onSuccess, onError) => ({
    type: constants.API,
    payload: {
        method: 'PUT',
        url: '/profiles/' + profileUuid + '/settings/avatar/',
        data,
        postProcessSuccess: onSuccess,
        postProcessError: onError
    }
});

export const fetchMultimediaCollection = (profileUuid, params, onSuccess, onError) => ({
    type: constants.API,
    payload: {
        method: 'GET',
        url: '/profiles/' + profileUuid + '/multimedia/',
        params,
        postProcessSuccess: onSuccess,
        postProcessError: onError
    }
});