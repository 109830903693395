import React from 'react';
import { Link } from "react-router-dom";
import Moment from "react-moment";

import {useTranslation} from "react-i18next";

import moment from "moment";

import {
    faTrash
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {connect} from "react-redux";

const SpecialActivityHeader = ({ special, author, user, occurredAt, handleDeleteClicked }) => {
    const { t, i18n } = useTranslation('ActivityFeed');

    const specialTranslations = {
        avatar_changed: 'ActivityFeed:activity.header.special.profileHasNewAvatar',
        joined_challenge: 'ActivityFeed:activity.header.special.profileHasJoinedTheChallenge',
        had_training: 'ActivityFeed:activity.header.special.profileHadTraining'
    }

    return (
        <div className='activity-header'>
            <div className='meta'>
                <div className='name'>
                    <Link to={ '/' + author.path + '/' }>{ author.name }</Link> {t(specialTranslations[special]) }
                </div>
                <div className='publish-date' title={moment(occurredAt).format('LLLL')}>
                    <Moment fromNow={ true } locale={ i18n.language } interval={30000} date={ occurredAt } />
                </div>
            </div>
            <div className='options'>
                { user.profiles.findIndex(profile => profile.uuid === author.uuid) > -1 &&
                    <button className='small none' onClick={ handleDeleteClicked }>
                        <FontAwesomeIcon style={{fontSize: '12px'}} icon={faTrash}/>
                    </button>
                }
            </div>
        </div>
    );
}

const mapStateToProps = (state) => ({
    user: state.user
});

export default connect(mapStateToProps, null)(SpecialActivityHeader);