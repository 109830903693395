import * as constants from './../constants';

export const fetchChallenge = (challengeUuid, onSuccess, onError) => ({
    type: constants.API,
    payload: {
        method: 'GET',
        url: '/challenges/' + challengeUuid + '/',
        postProcessSuccess: onSuccess,
        postProcessError: onError
    }
});

export const fetchChallengeTrainings = (challengeUuid, onSuccess, onError) => ({
    type: constants.API,
    payload: {
        method: 'GET',
        url: '/challenges/' + challengeUuid + '/trainings/',
        postProcessSuccess: onSuccess,
        postProcessError: onError
    }
});

export const joinChallenge = (challengeUuid, data, onSuccess, onError) => ({
    type: constants.API,
    payload: {
        method: 'POST',
        url: '/challenges/' + challengeUuid + '/participants/',
        success: (response) => ({ type: constants.PARTICIPATE_CHALLENGES, payload: response}),
        data,
        postProcessSuccess: onSuccess,
        postProcessError: onError
    }
});

export const fetchChallenges = (params, onSuccess, onError) => ({
    type: constants.API,
    payload: {
        method: 'GET',
        url: '/challenges/',
        success: (response) => ({ type: constants.FETCH_PARTICIPATING_CHALLENGES, payload: response}),
        params: params,
        postProcessSuccess: onSuccess,
        postProcessError: onError
    }
});

export const fetchDisciplines = (onSuccess, onError) => ({
    type: constants.API,
    payload: {
        method: 'GET',
        url: '/disciplines/',
        postProcessSuccess: onSuccess,
        postProcessError: onError
    }
});