import React from "react";
import Avatar from "../../../../../_ui/Avatar/Avatar";

const ChallengeParticipant = ({ avatar, name }) => {
    return <div className='participant'>
        <Avatar image={ avatar + '?size=100' } label={ name } />
        <span>{ name.split(' ')[0] }</span>
    </div>
}

export default ChallengeParticipant;