import React, { useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import './styles.scss';

const Modal = ({ style, initialIsOpened, title, footer, hideClose, onCloseHandler, className, children }) => {
    const [isOpened, setIsOpened] = useState(initialIsOpened);

    React.useEffect(() => {
        setIsOpened(initialIsOpened);
    }, [initialIsOpened])

    if (!isOpened) return <></>;

    const handleClose = (e) => onCloseHandler ? onCloseHandler(e) : setIsOpened(false);

    const header = <div className='header'>
        { title && <span className='title'>{ title }</span>}
        { !hideClose && <span className='close' onClick={ (e) => handleClose(e) }>&times;</span> }
    </div>;

    return <div style={{background: "rgba(204, 204, 204, .75)", zIndex: 999, position:"fixed", left: 0, right: 0, top: 0, bottom: 0, display: isOpened ? "block" : "none"}}>
        <div style={style} className={ classNames('modal', className) }>
            { (title || !hideClose) &&  header}
            <div className='body'>{ children }</div>
            { footer && <div className='footer'>{ footer }</div> }
        </div>
    </div>
}

Modal.propTypes = {
    initialIsOpened: PropTypes.bool.isRequired,
    title: PropTypes.string,
    showClose: PropTypes.bool,
    onCloseHandler: PropTypes.func,
    children: PropTypes.any.isRequired,
}

export default Modal;