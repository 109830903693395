import React from 'react';
import { connect } from "react-redux";

const requireGuest = (ComposedComponent) => {
    class Authenticate extends React.Component {
        componentDidMount() {
            if (this.props.user.isLoggedIn) {
                this.props.history.push('/');
                return false;
            }
        }

        componentDidUpdate(prevProps, prevState, snapshot) {
            if (this.props.user.isLoggedIn) {
                this.props.history.push('/');
                return false;
            }
        }

        render() {
            if (!this.props.user.isLoggedIn) {
                return (<ComposedComponent {...this.props} />);
            }

            return (<React.Fragment />);
        }
    }

    function mapStateToProps(state) {
        return {
            user: state.user
        }
    }

    return connect(mapStateToProps)(Authenticate);
}

export default requireGuest;