import React from 'react';
import {useTranslation} from "react-i18next";
import ActivityTag from "../../ActivityTag";
import {Link} from "react-router-dom";

const ActivityFooter = ({ uuid, comments, tags, commentsCount, reactions, showComments }) => {
    const { t } = useTranslation('ActivityFeed');
    return <div className='footer'>
        {!comments.off &&
        <div className='comments-label'>
            <Link to='/' onClick={ (e) => { e.preventDefault(); showComments(); } }>
                { commentsCount > 0
                    ? t('ActivityFeed:comments.label', { count: commentsCount })
                    : t('ActivityFeed:comments.label_zero', { count: commentsCount })
                }
            </Link>
        </div> }
        { tags && tags.length > 0 && <div className='tags'>
            { tags.map((tag, index) => <ActivityTag key={ index } label={ tag } path={ tag } /> ) }
        </div> }
    </div>
}

export default ActivityFooter;