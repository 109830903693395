import * as constants from './../constants';

export const fetchProfileFollowers = (profileUuid, onSuccess, onError) => ({
    type: constants.API,
    payload: {
        method: 'GET',
        url: '/profiles/' + profileUuid + '/followers/',
        success: (response) => ({ type: constants.FETCH_PROFILE_FOLLOWERS, payload: response}),
        postProcessSuccess: onSuccess,
        postProcessError: onError
    }
});

export const fetchProfilesFollowing = (profileUuid, onSuccess, onError) => ({
    type: constants.API,
    payload: {
        method: 'GET',
        url: '/profiles/' + profileUuid + '/following/',
        // success: (response) => ({ type: constants.FETCH_PROFILE_FOLLOWERS, payload: response }),
        postProcessSuccess: onSuccess,
        postProcessError: onError
    }
});

export const followProfile = (profileUuid, data, onSuccess, onError) => ({
    type: constants.API,
    payload: {
        method: 'POST',
        url: '/profiles/' + profileUuid + '/followers/',
        data,
        success: (response) => ({ type: constants.FETCH_PROFILE_FOLLOWERS, payload: response}),
        postProcessSuccess: onSuccess,
        postProcessError: onError
    }
});

export const updateFollowerStatus = (profileUuid, followerProfileUuid, status, onSuccess, onError) => ({
    type: constants.API,
    payload: {
        method: 'PATCH',
        url: '/profiles/' + profileUuid + '/followers/' + followerProfileUuid + '/',
        data: {status: status},
        success: (response) => ({ type: constants.FETCH_PROFILE_FOLLOWERS, payload: response}),
        postProcessSuccess: onSuccess,
        postProcessError: onError
    }
});