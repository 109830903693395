import React from 'react';
import './HamburgerToggle.scss';


const HamburgerToggle = () => {
    return (
        <div className={'hamburger-toggle'}>
            <div className={'bar-wrapper'}>
                <span className={'bar'} />
                <span className={'bar'} />
                <span className={'bar'} />
                <span className={'bar'} />
            </div>
        </div>
    );
}

export default HamburgerToggle;