import axios from "axios";

import * as constants from './constants';
import {logout} from "./actions/authActions";
import {addFlashMessage} from "./actions/flashMessageActions";
import {stringify} from "qs";

export const apiMiddleware = ({ dispatch, getState }) => next => action => {
    if (!action || action.type !== constants.API) return next(action);

    const BASE_URL = process.env.REACT_APP_API_URL ?? '';
    const AUTH_TOKEN = getState().authorization.token;

    const { url, method, success, data, params, postProcessSuccess, postProcessError } = action.payload;

    if (AUTH_TOKEN) {
        axios.defaults.headers.common['Authorization'] = `Bearer ${AUTH_TOKEN}`;
    } else {
        delete axios.defaults.headers.common['Authorization'];
    }

    axios.defaults.headers.common['Accept-Language'] = localStorage.getItem('i18nextLng');

    function prepareData(data) {
        if (!data) return null;

        for (let propName in data) {
            if (data[propName] === '') {
                data[propName] = null;
            } else if (data[propName] === undefined) {
                delete data[propName];
            }
        }

        return data
    }

    axios({
        method,
        url: BASE_URL + url,
        data: prepareData(data),
        params: params ? params : null,
        paramsSerializer: params => {
            return stringify(params)
        }
    }).then((response) => {
        if (success) dispatch(success(response.data));
        if (postProcessSuccess) postProcessSuccess(response.data);
    }).catch(err => {
        if (!err.response) console.warn(err);
        else {
            if (err.response && err.response.status === 401) {
                if (url === '/auth/refresh/' && postProcessError) {
                    postProcessError(err.response.data);
                } else {
                    dispatch(logout());
                }
            } else if (err.response && (err.response.status >= 400 && err.response.status < 500)) {
                if (err.response.status === 409) dispatch(addFlashMessage(err.response.data.message, "error"));
                if (err.response.status === 403) dispatch(addFlashMessage(err.response.data.message, "error"));
                if (postProcessError) postProcessError(err.response.data);
            } else if (err.response && err.response.status >= 500) {
                dispatch(addFlashMessage(err.response.data.message ?? "Something went wrong", "error"));
            }
        }
    })
};