import React from "react";
import { useTranslation } from "react-i18next";
import {Panel} from "../../UI";

const NoMoreContent = ({ profile }) => {
    const { t } = useTranslation('ActivityFeed');

    return <Panel className='no-content'>
        { profile === undefined
            ? t('ActivityFeed:noMoreContent.wall')
            : t(
                'ActivityFeed:noMoreContent.profile',
                { name: profile.type === 'commercial' ? profile.name : profile.name.split(' ')[0] }
            )
        }
    </Panel>
}

export default NoMoreContent;