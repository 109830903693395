import React from 'react';
import VizSensor from "react-visibility-sensor";

import ActivityPlaceholder from "./ActivityPlaceholder";

const ActivityLoader = ({ fetchMore }) => {
    if (!fetchMore) return <></>;
    return (
        <VizSensor partialVisibility onChange={(isVisible) => { isVisible && fetchMore(); }}>
            <>
                <ActivityPlaceholder />
                <ActivityPlaceholder />
            </>
        </VizSensor>
    );
}

export default ActivityLoader;