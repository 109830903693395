import React, {useEffect, useState} from "react";
import { connect } from 'react-redux';
import {Link} from "react-router-dom";
import Ico from "../../_ui/Ico/Ico";
import DropDown from "../../_ui/DropDown/DropDown";
import { fetchNotifications, updateNotification } from "../../../redux/actions/notificationActions";
import Notification from "./Notification";
import Pusher from 'react-pusher';

const Notifications = ({ user, dispatchFetchNotifications, dispatchNotificationViewed }) => {
    const [notifications, setNotifications] = useState([]);

    useEffect(() => {
        let isSubscribed = true;
        dispatchFetchNotifications(
            (response) => isSubscribed ? setNotifications(response) : null
        )

        return () => (isSubscribed = false);
    }, [dispatchFetchNotifications]);

    const markAsViewed = (e, uuid) => {
        // e.preventDefault();
        dispatchNotificationViewed(uuid, (response) => {
            setNotifications(notifications.filter((n, i) => n.uuid !== uuid));
        });
    }

    return (
        <DropDown
            className={ 'notifications' }
            toggle={ <Link to='/'><Ico badge={ notifications.length } className='bell' /></Link> }
        >
            {
                notifications.length > 0
                    ? notifications.map(notification => <Notification key={ notification.uuid } notification={notification} markAsViewed={markAsViewed} />)
                    : <div>no new notifications</div>
            }
            <Pusher
                channel={'users.' + user.uuid + '.notifications'}
                event="NotificationWasCreated"
                onUpdate={ () => dispatchFetchNotifications((response) => setNotifications(response))}
            />
        </DropDown>
    );
}

const mapDispatchToProps = dispatch => ({
    dispatchFetchNotifications: (onSuccess) => dispatch(fetchNotifications({search: { status: 'new' }}, onSuccess)),
    dispatchNotificationViewed: (uuid, onSuccess) => dispatch(updateNotification(uuid, { status: 'viewed' }, onSuccess)),
});

const mapStateToProps = (state) => ({
    user: state.user
});

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);