import React from 'react';
import {Panel} from "../../../../UI";
import Avatar from "../../../../_ui/Avatar/Avatar";
import {Link} from "react-router-dom";
import Moment from "react-moment";
import {useTranslation} from "react-i18next";
import moment from "moment";

const Comment = ({ uuid, comment, author, createdAt, replies }) => {
    const { i18n } = useTranslation();

    return <div><Panel className='comment'>
        <div className='author'>
            <Link to={ author.path }>
                <Avatar className='small' image={ author.avatar } label={ author.name } />
                <span className='author-name'>{ author.name }</span>
            </Link>
            <span className='date' title={moment(createdAt).format('LLLL')}>
                <Moment fromNow={ true } locale={ i18n.language } interval={30000} date={ createdAt } />
            </span>
        </div>
        <div className='content'>{ comment }</div>
    </Panel></div>
}

export default Comment;