import React, {useEffect, useState} from "react";
import { connect } from "react-redux";
import { Route, Switch, useHistory } from "react-router-dom";

import { logout } from "../redux/actions/authActions";

import requireUser from "../utils/requireUser";
import requireGuest from "../utils/requireGuest";

import './App.scss';

import AuthKeeper from "./AuthKeeper";

import Spinner from "../components/_ui/Spinner/Spinner";
import Header from "../components/Header/Header";
import ScrollToTop from "../components/ScrollToTop/ScrollToTop";

import LandingPage from "../pages/LandingPage/LandingPage";
import About from "../pages/About/About";
import Wall from "../pages/Wall/Wall";
import Confirm from "../pages/Confirm/Confirm";
import FlashMessageList from "../components/FlashMessage/FlashMessageList";
import Profile from "../pages/Profile/Pofile";
import Challenge from "../pages/Challenge/Challenge";
import UI from "../pages/UI/UI";
import Topic from "../pages/Topic/Topic";
import Settings from "../pages/Settings/Settings";

const App = ({ dispatchLogoutAction }) => {
    const history = useHistory();
    const [loading, setLoading] = useState(true);

    const loader = <div style={{textAlign: 'center', padding: "100px"}}><Spinner/></div>;

    useEffect(() => {
        history.listen((location) => {
            window.ga('set', 'page', location.pathname + location.search);
            window.ga('send', 'pageView');
        });
    }, [history]);

    const app = <>
        <Header dispatchLogoutAction={ dispatchLogoutAction }/>
        <ScrollToTop />
        <Switch>
            <Route exact path="/ui/" component={ UI } />
            <Route exact path="/about/(terms-of-service|privacy-policy)/" component={About}/>
            <Route exact path="/hi/(sign-up|reset-password-request/)?" component={ requireGuest(LandingPage) }/>
            <Route exact path="/challenges/:challengeUuid([a-z0-9-]+)/" component={ requireUser(Challenge) }/>
            <Route exact path="/topics/:tag([a-zA-Z0-9-]+)/" component={ requireUser(Topic) }/>
            <Route exact path="/settings/" component={ requireUser(Settings) }/>
            <Route exact path="/" component={ requireUser(Wall) } label='wall' />
            <Route exact path="/confirm/:token([a-z0-9]+)/" component={ Confirm }/>
            <Route exact path="/:profilePath([a-z0-9-]+)/" component={ requireUser(Profile) }/>
        </Switch>
        <FlashMessageList />
    </>

    return (
        <React.Suspense fallback={<div style={{textAlign: 'center', padding: "100px"}}><Spinner /></div>}>
            <AuthKeeper globalLoading={ loading } setGlobalLoading={ setLoading } />
            { loading ? loader : app }
        </React.Suspense>
    );
}

const mapDispatchToProps = (dispatch) => ({
    "dispatchLogoutAction": (onSuccess, onError) => dispatch(logout(onSuccess, onError))
});

export default connect(null, mapDispatchToProps)(App);
