import React from "react";
import classNames from "classnames/bind";

import './Slogan.scss';

const Slogan = ({ firstLine, secondLine, style, className }) => {

    const sloganClass = {
        slogan: true
    };

    return (
        <h2 className={  classNames(sloganClass, className) } style={ style }>
            <span
                className="first-line"
                dangerouslySetInnerHTML={{ __html: firstLine.replace('i', '<span class="i">i</span>') }}
            />
            <span className="second-line">{ secondLine }</span>
        </h2>
    );
}

export default Slogan;