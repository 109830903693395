import React, {useEffect, useState} from 'react';
import SocialToolsSection from "./components/Section/SocialToolsSection";
import ActivityToolsSection from "./components/Section/ActivityToolsSection";
import ActivityFeedSection from "./components/Section/ActivityFeedSection";

import './Wall.scss';
import classNames from "classnames";

export const Wall = () => {
    const [activeColumn, setActiveColumn] = useState('feed');

    useEffect(() => {
        document.title = 'Sportzzy';
    }, []);
    return (

        <div className='wall-page'>
            <div className='three-columns-page'>
                <div className={ classNames('column', { 'mobile-active': activeColumn === 'social' }) }>
                    <div className='sticky-top'>
                        <SocialToolsSection />
                    </div>
                </div>
                <div className={ classNames('main', { 'mobile-active': activeColumn === 'feed'}) }>
                    <ActivityFeedSection />
                </div>
                <div className={ classNames('column', { 'mobile-active': activeColumn === 'activity'}) }>
                    <div className='sticky-top'>
                        <ActivityToolsSection />
                    </div>
                </div>
            </div>
            <div className='mobile-switch'>
                <div onClick={ () => setActiveColumn('social') }>Social</div>
                <div onClick={ () => setActiveColumn('feed') }>Feed</div>
                <div onClick={ () => setActiveColumn('activity') }>Activity</div>
            </div>
        </div>
    );
}

export default Wall;