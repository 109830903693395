import React, {useEffect, useRef, useState} from "react";

import Crop from "../../../components/Crop/Crop";
import empty from "../../../components/_ui/Avatar/assets/avatar.png";

import './ChangeDetailsForm.scss';
import Avatar from "../../../components/_ui/Avatar/Avatar";
import {changeAvatar, fetchMultimediaCollection, removeAvatar} from "../../../redux/actions/profileActions";
import {connect} from "react-redux";
import '@fortawesome/fontawesome-svg-core/styles.css'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUpload} from "@fortawesome/free-solid-svg-icons";

const AvatarSettings = ({ dispatchChangeAvatar, dispatchRemoveAvatar, dispatchFetchAvatarCollection, dispatchFetchMultimediaCollection, profile, onChanged, cancelHandler }) => {
    const [avatar, setAvatar] = useState(profile.avatar);
    const [avatarCollection, setAvatarCollection] = useState([]);
    const [activityMultimediaCollection, setActivityMultimediaCollection] = useState([]);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [avatarSettings, setAvatarSettings] = useState(null);
    const [croppedImage, setCroppedImage] = useState(null);
    const isSubscribed = useRef(true);

    useEffect(() => {
        isSubscribed.current = true;
        dispatchFetchAvatarCollection(
            profile.uuid,
            (response) => isSubscribed.current ? setAvatarCollection(response) : null,
            (errorResponse) => isSubscribed.current ? console.log(errorResponse) : null
        )

        dispatchFetchMultimediaCollection(
            profile.uuid,
            (response) => isSubscribed.current ? setActivityMultimediaCollection(response) : null,
            (errorResponse) => isSubscribed.current ? console.log(errorResponse) : null
        );

        return () => { isSubscribed.current = false };
    }, [profile, dispatchRemoveAvatar, dispatchFetchAvatarCollection]);

    const handleSaveClick = e => {
        e.preventDefault();
        setIsSubmitting(true);
        if (avatar === profile.avatar) {
            cancelHandler();
        } else if (avatar === empty) {
            dispatchRemoveAvatar(profile.uuid, () => {
                onChanged(avatar);
                cancelHandler();
                setIsSubmitting(false)
            });
        } else if (avatarSettings && avatarSettings.multimedia.uuid) {
            dispatchChangeAvatar(profile.uuid, avatarSettings, () => {
                cancelHandler();
                onChanged(avatar);
                setIsSubmitting(false)
            });
        } else if (avatarSettings && avatarSettings.multimedia.file) {
            dispatchChangeAvatar(profile.uuid, croppedImage ? {multimedia: {file: croppedImage}} : avatarSettings, () => {
                cancelHandler();
                onChanged(croppedImage);
                setIsSubmitting(false)
            });
        } else {
            console.log('???');
            cancelHandler();
        }
    }

    const handleAvatarRemoveClick = (e) => {
        e.preventDefault();
        setAvatar(empty);
        setAvatarSettings(null);
    }

    const handleAvatarChangeClick = (e, avatar) => {
        e.preventDefault();
        setAvatar(avatar.url + '?token=' + JSON.parse(localStorage.getItem('authorization')).token);
        setAvatarSettings({multimedia: {uuid: avatar.uuid}});
    }
    const handleMultimediaClick = (e, avatar) => {
        let img = readFile(avatar.url  + '?token=' + JSON.parse(localStorage.getItem('authorization')).token);

        let canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;
        let ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);
        let dataURL = canvas.toDataURL("image/png");
        let base = dataURL.replace(/^data:image\/(png|jpg);base64,/, "");

        setAvatar(base);
        setAvatarSettings({multimedia: {file: base}});
    }

    const onFileChange = async (e) => {
        if (e.target.files && e.target.files.length > 0) {
            const file = e.target.files[0]
            let imageDataUrl = await readFile(file)
            setAvatar(imageDataUrl);
            setAvatarSettings({multimedia: {file: imageDataUrl}})
        }
    }

    return <form className='change-profile-details-form'>
        <div className='change-profile-details-container'>
            <div className='avatar-preview'>
                {/*{ avatarSettings.multimedia.file && <Crop initialImage={ uploadedFile } /> }*/}
                {/*{ avatarSettings.multimedia.uuid && <Avatar image={ avatarSettings.multimedia.url } className={'big'} /> }*/}
                {/*{ avatarSettings.clear && <Avatar className={'big'} /> }*/}
                {/*{ !avatarSettings && <Avatar image={ avatar } className={'big'} /> }*/}
                { avatarSettings && avatarSettings.multimedia.file
                    ? <Crop onCropped = { (file) => {setCroppedImage(file);}} initialImage={ avatarSettings.multimedia.file ? avatarSettings.multimedia.file : avatarSettings.multimedia.url } />
                    : <div className='avatar-container'>
                        <Avatar image={avatar} className={'big'} style={{width: "100%", height: "100%"}}/>
                    </div>
                }
            </div>
            <div className='avatar-select-options'>
                <div className={'gallery'}>
                    <div className="upload-btn-wrapper">
                        <button><FontAwesomeIcon size={'lg'} style={{ fontSize: '12px' }} icon={ faUpload } /></button>
                        <input type="file" accept='image/*'  onChange={ onFileChange } />
                    </div>

                    <div className={'none'}>
                        <img src={empty}  style={{width: "75px", height:"75px"}} alt={'none'} title={'none'} onClick={handleAvatarRemoveClick } />
                    </div>
                </div>
                <label>Previously used</label>
                <div className={'gallery'}>
                    { avatarCollection.map(avatar =>
                            <div key={avatar.uuid} className={'previous'}>
                                <img src={avatar.url + '?token=' + JSON.parse(localStorage.getItem('authorization')).token}  style={{display: "inline-block", width: "75px", height:"75px"}} alt={'none'} title={'avatar'} onClick={(e) => handleAvatarChangeClick(e, avatar) } />
                            </div>
                        ) }
                    <div className={'spacer'}></div>
                </div>
                <label>Yours images</label>
                <div className={'gallery'}>
                    { activityMultimediaCollection.map(avatar =>
                        <div key={avatar.uuid} className={'previous'}>
                            <img src={avatar.url + '?token=' + JSON.parse(localStorage.getItem('authorization')).token}  style={{display: "inline-block", width: "75px", height:"75px"}} alt={'none'} title={'multimedia'} />
                        </div>
                    ) }
                    <div className={'spacer'}></div>
                </div>

            </div>
        </div>
        <div className='footer'>
            <button onClick={ cancelHandler } className={'small'}>cancel</button>
            <button disabled={ isSubmitting || avatar === profile.avatar && avatarSettings === null} onClick={ handleSaveClick } className='small primary'>save</button>
        </div>
    </form>
};

function readFile(file) {
    return new Promise((resolve) => {
        const reader = new FileReader()
        reader.addEventListener('load', () => resolve(reader.result), false)
        reader.readAsDataURL(file)
    })
}

const mapDispatchToProps = (dispatch) => ({
    dispatchRemoveAvatar: (profileUuid, onSuccess, onError) => dispatch(removeAvatar(profileUuid, onSuccess, onError)),
    dispatchChangeAvatar: (profileUuid, data, onSuccess, onError) => dispatch(changeAvatar(profileUuid, data, onSuccess, onError)),
    dispatchFetchAvatarCollection: (profileUuid, onSuccess, onError) => dispatch(fetchMultimediaCollection(profileUuid, {type:"avatar"}, onSuccess, onError)),
    dispatchFetchMultimediaCollection: (profileUuid, onSuccess, onError) => dispatch(fetchMultimediaCollection(profileUuid, {type:"activity"}, onSuccess, onError)),
});

export default connect(null, mapDispatchToProps)(AvatarSettings);