import React from 'react';
import { Link } from "react-router-dom";
import Moment from "react-moment";

import {useTranslation} from "react-i18next";

import Avatar from "../../../_ui/Avatar/Avatar";
import moment from "moment";

import {
    faTrash
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {connect} from "react-redux";

const ActivityHeader = ({ author, user, occurredAt, handleDeleteClicked }) => {
    const { i18n } = useTranslation(['activities']);

    return (
        <div className='activity-header'>
            <div className='avatar-column'>
                <Link to={ '/' + author.path + '/' }>
                    <Avatar className='large' image={ author.avatar + "?size=100" } label={ author.name } />
                </Link>
            </div>
            <div className='meta'>
                <div className='name'>
                    <Link to={ '/' + author.path + '/' }>{ author.name }</Link>
                </div>
                <div className='publish-date' title={moment(occurredAt).format('LLLL')}>
                    <Moment fromNow={ true } locale={ i18n.language } interval={30000} date={ occurredAt } />
                </div>
            </div>
            <div className='options'>
                { user.profiles.some(profile => profile.uuid === author.uuid) &&
                    <button className='small none' onClick={ handleDeleteClicked }>
                        <FontAwesomeIcon style={{fontSize: '12px'}} icon={faTrash}/>
                    </button>
                }
            </div>
        </div>
    );
}

const mapStateToProps = (state) => ({
    user: state.user
});

export default connect(mapStateToProps, null)(ActivityHeader);