import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import Avatar from "../../../../../components/_ui/Avatar/Avatar";
import {fetchProfiles} from "../../../../../redux/actions/profileActions";
import {updateFollowerStatus} from "../../../../../redux/actions/profileFollowerAction";

export const FollowersAwaiting = ({user, dispatchFetchFindAwaitingProfiles, dispatchUpdateFollowerStatus}) => {
    const { t } = useTranslation(["followers"]);
    const [foundProfiles, setFoundProfiles] = useState([]);

    useEffect(() => {
        let isSubscribed = true;
        dispatchFetchFindAwaitingProfiles(
            (response) => isSubscribed ? handleFetchProfileAwaitingFollowersSuccess(response) : null
        );

        return () => { isSubscribed = false };
    }, [dispatchFetchFindAwaitingProfiles]);

    const handleFetchProfileAwaitingFollowersSuccess = response => {
        setFoundProfiles(response);
    }

    const handleConfirmFollower = (e) => {
        e.preventDefault();

        let profiles = foundProfiles;
        let profileIndex = profiles.findIndex(el => el.uuid === e.target.getAttribute('data-profile-uuid'));
        delete profiles.splice(profileIndex, 1);

        dispatchUpdateFollowerStatus(
            user.profile.uuid,
            e.target.dataset.profileUuid,
            'confirmed',
            () => setFoundProfiles([...profiles])
        );
    }

    const handleRejectFollower = (e) => {
        e.preventDefault();

        let profiles = foundProfiles;
        let profileIndex = profiles.findIndex(el => el.uuid === e.target.getAttribute('data-profile-uuid'));
        delete profiles.splice(profileIndex, 1);

        dispatchUpdateFollowerStatus(
            user.profile.uuid,
            e.target.dataset.profileUuid,
            'rejected',
            () => setFoundProfiles([...profiles])
        );
    }

    if (foundProfiles.length === 0) {
        return <></>;
    }

    return (
        <div>
            <h6>{ t('followers:section.awaiting.title') }</h6>
            <div className={'results'}>
                <ul>
                    {foundProfiles.map((profile) =>
                        <li key={profile.uuid} style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '10px 0'}}>
                            <div>
                                <Link style={{display: 'flex', alignItems: 'center', textDecoration: 'none', fontSize: '18px'}} to={ '/' + profile.path + '/'} title={profile.name} >
                                    <Avatar image={profile.avatar} className='small' style={{marginRight: '5px'}} /> { profile.name }
                                </Link>
                            </div>
                            <div style={{textAlign: 'right', textDecoration: 'none', fontSize: '16px'}}>
                                <Link
                                    style={{paddingLeft: '10px', paddingRight: '10px'}}
                                    className='btn small primary'
                                    to={'/'}
                                    data-profile-uuid={ profile.uuid }
                                    onClick={ handleConfirmFollower }
                                >
                                    { t('followers:section.awaiting.button.confirm') }
                                </Link>
                                <Link
                                    style={{paddingLeft: '10px', paddingRight: '10px'}}
                                    className='btn small danger'
                                    to={'/'}
                                    data-profile-uuid={ profile.uuid }
                                    onClick={ handleRejectFollower }
                                >
                                    &#10005;
                                </Link>
                            </div>
                        </li>
                    )}
                </ul>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => ({ user: state.user});
const mapDispatchToProps = (dispatch) => ({
    dispatchFetchFindAwaitingProfiles: (onSuccess) => dispatch(fetchProfiles({search: {followerStatus: 'awaiting_confirm'}}, onSuccess, (errorResponse) => console.log(errorResponse))),
    dispatchUpdateFollowerStatus: (profileUuid, followerProfileUuid, status, onSuccess) => dispatch(updateFollowerStatus(profileUuid, followerProfileUuid, status, onSuccess, (errorResponse) => console.log(errorResponse)))
});

export default connect(mapStateToProps, mapDispatchToProps)(FollowersAwaiting);