import { ADD_FLASH_MESSAGE, DELETE_FLASH_MESSAGE } from '../constants';
import findIndex from 'lodash/findIndex';

export default function flashMessageReducer(state = [], action = {}) {

    switch(action.type) {
        case ADD_FLASH_MESSAGE:
            return [...state, action.payload];
        case DELETE_FLASH_MESSAGE:
            const index = findIndex(state, { id: action.payload.id });
            if (index >= 0) {
                return [
                    ...state.slice(0, index),
                    ...state.slice(index + 1)
                ];
            }
            return state;

        default:
            return state;
    }
}
