import React from 'react';

const ActivityPlaceholder = () => {
    return (
        <div className='activity placeholder'>
            <div className='activity-header'>
                <div className='avatar-column'>

                    <div className={'placeholder'} style={{ marginRight: "15px", backgroundColor: "#e1e1e1", width: '45px', height: '45px', borderRadius: '50%' }} />

                </div>
                <div className='meta'>
                    <div className='name'>
                        <span className={'placeholder'} style={{ display: 'block', backgroundColor: "#e1e1e1", width:"200px", height: '24px' }} />
                    </div>
                    <div className='publish-date'>
                        <span className={'placeholder'} style={{ marginTop: '5px', display: 'block', backgroundColor: "#e1e1e1", height: '17px', width: '70px' }} />
                    </div>
                </div>
                <div className='options'>

                </div>
            </div>

            <div className='content'>
                <div className='text'>
                    <span style={{ display: 'inline-block', backgroundColor: "#e1e1e1", margin: '5px', height: '23px', width: '125px' }} />
                    <span style={{ display: 'inline-block', backgroundColor: "#e1e1e1", margin: '5px', height: '23px', width: '25px' }} />
                    <span style={{ display: 'inline-block', backgroundColor: "#e1e1e1", margin: '5px', height: '23px', width: '170px' }} />
                    <span style={{ display: 'inline-block', backgroundColor: "#e1e1e1", margin: '5px', height: '23px', width: '50px' }} />
                    <span style={{ display: 'inline-block', backgroundColor: "#e1e1e1", margin: '5px', height: '23px', width: '110px' }} />
                    <span style={{ display: 'inline-block', backgroundColor: "#e1e1e1", margin: '5px', height: '23px', width: '25px' }} />
                    <span style={{ display: 'inline-block', backgroundColor: "#e1e1e1", margin: '5px', height: '23px', width: '150px' }} />
                    <span style={{ display: 'inline-block', backgroundColor: "#e1e1e1", margin: '5px', height: '23px', width: '115x' }} />
                    <span style={{ display: 'inline-block', backgroundColor: "#e1e1e1", margin: '5px', height: '23px', width: '125px' }} />
                    <span style={{ display: 'inline-block', backgroundColor: "#e1e1e1", margin: '5px', height: '23px', width: '25px' }} />
                    <span style={{ display: 'inline-block', backgroundColor: "#e1e1e1", margin: '5px', height: '23px', width: '170px' }} />
                    <span style={{ display: 'inline-block', backgroundColor: "#e1e1e1", margin: '5px', height: '23px', width: '50px' }} />
                    <span style={{ display: 'inline-block', backgroundColor: "#e1e1e1", margin: '5px', height: '23px', width: '110px' }} />
                    <span style={{ display: 'inline-block', backgroundColor: "#e1e1e1", margin: '5px', height: '23px', width: '25px' }} />
                    <span style={{ display: 'inline-block', backgroundColor: "#e1e1e1", margin: '5px', height: '23px', width: '150px' }} />
                    <span style={{ display: 'inline-block', backgroundColor: "#e1e1e1", margin: '5px', height: '23px', width: '115x' }} />
                </div>
            </div>


            <div className='footer'>
                <div className='comments-label'>
                    <span className={'placeholder'} style={{ display: 'inline-block', backgroundColor: "#e1e1e1", margin: '5px', height: '16px', width: '100px' }} />
                    <span className={'placeholder'} style={{ display: 'inline-block', backgroundColor: "#e1e1e1", margin: '5px', height: '16px', width: '25px' }} />
                </div>
            </div>
        </div>
    )
}

export default ActivityPlaceholder;