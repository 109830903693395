import React from "react";
import {prepareTitle} from "../../../utils/challengeUtils";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";

const Notification = ({ notification, markAsViewed }) => {
    const { t, i18n } = useTranslation(['Challenge', 'challenges', 'disciplines']);

    const getTemplate = notification => {
        switch (notification.type) {
            case 'ParticipantHasJoinedChallenge':
                return <Link
                    to={ '/challenges/' +  notification.challenge.uuid + '/' }
                    onClick={ (e) => markAsViewed(e, notification.uuid) }
                >{ t('challenges:notification.participantHasJoined', { "name": notification.from.name, "challenge": prepareTitle(notification.challenge, t, i18n) }) }</Link>;
            case 'NewProfileFollower':
                return <Link
                    to={ '/' +  notification.from.path + '/' }
                    onClick={ (e) => markAsViewed(e, notification.uuid) }
                >{ notification.from.name } started following you</Link>;
            default:
                return <>{ notification.from.name } { notification.type }</>;
        }
    }

    return (
        <div className='notification'>
            { getTemplate(notification) }
        </div>
    );
}

export default Notification;