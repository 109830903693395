import React, {useRef, useState} from "react";
import classNames from "classnames/bind";
import { Input } from "../index";

import '../style.scss';

const Select = (props) => {
    const searchInputRef = useRef(null);
    const [value, setValue] = useState(props.value ? props.value : '');
    const [open, setOpen] = useState(false);
    const [search, setSearch] = useState('');
    const [label, setLabel] = useState(!value || value === '' ? props.placeholder : 'aaa');

    const defaultClassNames = {
        input: true,
        select: true,
    };

    const handleToggle = () => {
        searchInputRef.current.focus();
        setOpen(!open);
    }

    const handleSetOption = (e, option) => {
        e.preventDefault();
        props.onChange(option.value);
        setValue(option.value);
        setLabel(option.label);
        setOpen(false);
        setSearch('');
    }

    const filterOptions = (options, phrase) => {
        if (phrase === '') {
            return options;
        }

        return options.filter((option) => option.value.indexOf(search) > -1 || option.label.indexOf(search) > -1);
    }

    return (
        <div
            className={ classNames(defaultClassNames, props.className, { open: open }) }
            // name={ props.name }
            // onBlur={ props.onBlur}
            // onChange={ props.onChange }
            // onClick={ props.onClick }
            // onFocus={ props.onFocus }
            // placeholder={ props.placeholder }
            style={ props.style }
            // value={ props.value }
        >
            <div onClick={ handleToggle } className={ classNames('label', { placeholder: value === '' }) }>{ label }</div>
            <div className='select-inside'>
                <div onClick={ handleToggle } className={ classNames('label', { placeholder: value === '' }) }>{ label }</div>
                <Input
                    customRef={ searchInputRef }
                    placeholder={ props.searchPlaceholder ? props.searchPlaceholder : 'Search...' }
                    value={ search }
                    onChange={ e => setSearch(e.target.value) }
                    className={ classNames('search', props.className) }
                />
                <div className={ 'options' }>
                { filterOptions(props.options, search).sort((a, b) => (a.label > b.label) ? 1 : -1).map(
                    (option) => <a style={{ display: 'block' }} key={ option.value } href='/' className={ classNames('option', { selected: value === option.value }) } onClick={ e => handleSetOption(e, option) }>{ option.label }</a>)
                }
                </div>
            </div>
        </div>
    )
}

export default Select;