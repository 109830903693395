import * as constants from "../constants";

export const fetchNotifications = (params, onSuccess, onError) => ({
    type: constants.API,
    payload: {
        method: 'GET',
        url: '/notifications/',
        params,
        // success: (response) => ({ type: constants.ACTIVITIES_FETCHED, payload: response}),
        postProcessSuccess: onSuccess,
        postProcessError: onError
    }
});

export const updateNotification = (uuid, data, onSuccess, onError) => ({
    type: constants.API,
    payload: {
        method: 'PATCH',
        url: '/notifications/' + uuid + '/',
        data,
        postProcessSuccess: onSuccess,
        postProcessError: onError
    }
});