import React from 'react';

import ProfileHeaderLoader from "./components/ProfileHeaderLoader";

const ProfileLoader = () => {
    return (
        <div className='profile-page'>
            <ProfileHeaderLoader />
            {/*<div className='three-columns-page'>*/}
            {/*    <div style={{ height: '1000px'}} className='main'>*/}
            {/*        <div className={'activity-feed-container'}>*/}
            {/*            <div className={'activity-feed'}>*/}
            {/*            <ActivityPlaceholder />*/}
            {/*            <ActivityPlaceholder />*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>*/}
        </div>
    );
}

export default ProfileLoader;